import React from 'react';
import LabelSelection from './label_selection';
import LabelGeneration from './label_generation';
import LabelSuccess from './label_success';
import LabelFailure from './label_failure';
import LabelOverride from './label_override';
import WorkOrderRef from '../../workorder_reference/work_order_reference';
import { UserPermissionsContext } from '../../../context/user_permission';

/**
 * Component for label generation process
 * @function
 * @param {object} props - Props object
 * @returns {JSX} - Label component based on current status
 */
function Labels(props) {
  const is_wip_case = props.cases.ws_version === '1.0';
  const stage = is_wip_case || !props.gen_2 ? props.label_generation_status : props.stage;

  let displays = {
    'Label Selection': <LabelSelection {...props} />,
    'Label Generation Reprocess': <LabelSelection {...props} />,
    'Label Generation Process': <LabelGeneration {...props} />,
    'Label Generation Success': <LabelSuccess {...props} />,
    'Label Generation Failed': <LabelFailure {...props} />,
    'Label Manual Process': <LabelSuccess {...props} />,
    'Label Override Process': <LabelOverride {...props} />,
    '': props.loadingText(),
  };

  /**
   * Retrieves component to display when labels are complete
   * @function
   * @returns {JSX} - Component display
   */
  const getCompletionComponent = () => {
    let label_status = '';
    const valid_statuses = ['Label Generation Success', 'Label Override Process', 'Label Manual Process'];
    if (props.cases.cad_process.log) {
      const label_statuses = props.cases.cad_process.log.filter(
        (status) => status.status_code !== 'Production Process Complete' && status.status_code !== 'Labels Complete'
      );
      if (label_statuses.length > 0) {
        const status = label_statuses[label_statuses.length - 1].status_code;
        label_status = valid_statuses.indexOf(status) !== -1 ? status : '';
      }
    }
    return displays[label_status];
  };

  displays['Labels Complete'] = getCompletionComponent();

  return (
    <>
      <UserPermissionsContext>
        {(user_roles_and_permissions) => {
          return !props.is_case_blocked ? (
            <div className={props.gen_2 ? 'production-process__gen-2__labels' : ''}>
              {!is_wip_case && props.gen_2 && (
                <WorkOrderRef
                  label={'Production WO Ref:'}
                  classOverwrite={'production-label'}
                  placeholder={'WO1234567'}
                  ref_no={props.ref_no}
                  update_wo={props.update_wo}
                  permission="CASE_PROD_WO_EDIT"
                  status_code={props.cases.status_code}
                  disabled={props.disableWorkOrderRefComponent()}
                  ref_no_required={true}
                />
              )}
              {(!is_wip_case ? props.ref_no : true) && displays[stage]}
            </div>
          ) : null;
        }}
      </UserPermissionsContext>
    </>
  );
}

export default Labels;
