// External Libs
import React, { Component } from 'react';

// Internal Libs
import { userHasPermission } from '../../../common/permission';
import { UserPermissionsContext } from '../../../context/user_permission';

class CadLabelOverride extends Component {
  render() {
    return (
      <React.Fragment>
        {/* <div>WO Ref: {this.props.ref_no ? this.props.ref_no : 'N/A'}</div> */}
        {this.props.returnToSelection && this.props.ireq_status && this.props.ireq_status !== 'Item Request Shipped' ? (
          <UserPermissionsContext>
            {(user_roles_and_permissions) => {
              return userHasPermission('IREQ_LABEL_GENERATION', user_roles_and_permissions.permissions) && !this.props.shipping_approved_ind ? (
                <div>
                  {/* eslint-disable-next-line */}
                  <a className="process-return process-return-ir" onClick={this.props.restartLabelGeneration}>
                    <i className="fa fa-long-arrow-left" aria-hidden="true" /> Return to label selection
                  </a>
                </div>
              ) : null;
            }}
          </UserPermissionsContext>
        ) : null}
        <div>Label generation has been overridden...</div>
      </React.Fragment>
    );
  }
}

export default CadLabelOverride;
