/**
 * File:ir_submission.js - Component to submit item request on BPP side.
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './ir_submission.scss';

// External Libs
import _ from 'lodash';
import Axios from 'axios';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom'; //Fix: remove Link not being used

//Internal Libs
import { userHasPermission } from '../../common/permission';
// Internal Components
import ItemQuantity from './quantity';
import ItemReason from './reason';
import ItemReview from './review';
import Loader from '../../components/loader/loader';
import NextButton from '../../components/button/bpp_next_button';
import BackButton from '../../components/button/bpp_back_button';
import Submitter from '../../doctor/case_submission/submitting_loader';
import Modal from '../../components/modal/modal';
import Error from '../../doctor/404/not_found';
import IrReason from '../../common/ir_reason.json';
import IrReasonGen2 from '../../common/ir_reason_gen2.json';
import SessionExpire from '../../common/session_expire';

// Internal Functions
import {
  setTokenHeader,
  textFieldLimited,
  checkToken,
  isActiveAddressById,
  getActiveAddress,
  getDisabledListOfTeethByIDBTray,
  removeEmoji,
  clearIdbRequiredReason,
  clearIdbRequiredReasonForArchByQuantity,
  clearIdbRequiredReasonForArchWhenNoQuantity,
  clearIdbSelectedTeeth,
} from '../../common/functions';
import { handleHttpRequestError } from '../../common/error';
import { scrollToTop } from '../../common/scroll';
import { assignIdbPartNumbers } from '../../common/ir_helpers';

const ARCH_UPPER = 'upper';
const ARCH_LOWER = 'lower';
const REASON_CATEGORY_IDB_REQUIRED = 'idb_required';
const KEYWORD_IDB = 'idb';
const KEYWORD_UPPER = 'u';
const KEYWORD_LOWER = 'l';

/**
 * Used to allow a user to submit an item request
 * @component
 * @alias BusinessIrSubmission
 * @category BPP
 */
class IrSubmission extends Component {
  constructor(props) {
    super(props);
    const ir_reason_data = this.props.gen_2 ? _.cloneDeep(IrReasonGen2) : _.cloneDeep(IrReason);
    this.state = {
      request_id: '',
      case_id: '',
      doctor_first_name: '',
      doctor_last_name: '',
      showCancelCase: false,
      loading: true,
      item_request_details: [],
      addresses: [],
      address: [],
      patient_first_name: '',
      patient_last_name: '',
      step: 'item_parts',
      parts_quantity: [],
      item_parts: [],
      status_comment: '',
      props_item_parts_quantity: [],
      original_item_parts_quantity: [],
      item_parts_quantity: [],
      buttons: <NextButton position={'next'} buttonClass={'bpp-submit-next'} description={'Parts'} onButtonClick={this.onButtonClick} />,
      warning: false,
      brackets_has_quantity: false,
      smartwire_has_quantity: false,
      idb_has_quantity: false,
      error: false,
      error_type: '',
      shipping_address_id: 0,
      props_request_reasons: [],
      request_reasons: ir_reason_data,
      refresh: false,
    };

    this.goBack = this.goBack.bind(this);
    this.removeWizardErrorMsg = this.removeWizardErrorMsg.bind(this);
    this.totalPartQuantity = this.totalPartQuantity.bind(this);
    this.hasReasons = this.hasReasons.bind(this);
    this.redirectUserToCaseDetail = this.redirectUserToCaseDetail.bind(this);
    this.hasReasonsMatchingQuantities = this.hasReasonsMatchingQuantities.bind(this);
  }

  componentDidMount() {
    setTokenHeader();
    const that = this;
    let case_id = this.props.case_id;
    let shipping_address_id = this.state.shipping_address_id;
    if (this.props.item_request && this.props.item_request.shipping_address__id > 0 && this.props.mode === 'update') {
      shipping_address_id = this.props.item_request.shipping_address__id;
    }

    const remove_idb_tray =
      this.props.user_roles_and_permissions &&
      this.props.user_roles_and_permissions.permissions &&
      userHasPermission('IREQ_IDB_TRAY_UPDATE', this.props.user_roles_and_permissions.permissions)
        ? false
        : true;

    Axios.get(`/apiV2/itemrequest/${case_id}?use_idb_flag=${remove_idb_tray}`)
      .then((res) => {
        if (shipping_address_id === 0) {
          shipping_address_id = res.data.cases[0].shipping_address.id;
        }

        let active_addresses = [res.data.cases[0].shipping_address];

        if (!isActiveAddressById(shipping_address_id)) {
          active_addresses = getActiveAddress(res.data.shipping_addresses);
          shipping_address_id = active_addresses[0].id;
        }

        let counter = 0;
        let item_parts_quantity = that.state.item_parts_quantity;
        res.data.item_parts.forEach((item_part) => {
          item_parts_quantity.push({
            id: counter,
            part_id: item_part.part_id,
            quantity: 0,
            quantity_previous: 0,
            current_ind: item_part.current_ind,
            description: item_part.description,
            max_qty: item_part.override_max_qty,
            part_category: item_part.part_category,
            seq_no: item_part.seq_no,
            is_de: item_part.is_de,
          });
          counter++;
        });

        this.setState({
          case_id: case_id,
          loading: false,
          item_request_details: res.data,
          addresses: res.data.shipping_addresses,
          patient_first_name: res.data.patient.first_name,
          patient_last_name: res.data.patient.last_name,
          item_parts: res.data.item_parts,
          item_parts_quantity: item_parts_quantity,
          address: active_addresses[0],
          shipping_address_id: shipping_address_id,
          hide: item_parts_quantity.length === 0,
        });
      })
      .catch(function (err) {
        that.setState({
          loading: false,
          error: true,
        });
        handleHttpRequestError(err, that);
      });
  }

  componentDidUpdate() {
    //Check if user is still logged in
    const isAllowed = checkToken();
    if (!isAllowed) {
      this.props.history.push('/');
    }

    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.updateIr &&
      this.props.item_request &&
      this.props.item_request_detail &&
      this.props.history.location.state.updateIr === 'true' &&
      this.state.props_item_parts_quantity.length === 0 &&
      this.state.props_request_reasons.length === 0
    ) {
      let request_reasons = JSON.parse(_.cloneDeep(this.props.item_request.ireq_reason));
      let new_request_reason = _.cloneDeep(this.state.request_reasons);
      const result_idb_required = request_reasons.filter((r) => r.category === 'idb_required');
      new_request_reason.map(function (reason) {
        var result = request_reasons.filter((r) => r.name === reason.name && r.id === reason.id && r.category === reason.category);
        if (result.length > 0) {
          reason.booleanValue = result[0].booleanValue;
          reason.value = result[0].value;
          reason.selectedTeeth = result[0].selectedTeeth;
          if (reason.category === 'idb_required') {
            reason.segment_preference = result_idb_required[0].segment_preference;
            reason.segment_preference_text = result_idb_required[0].segment_preference_text;
            reason.arch_segments_options = result_idb_required[0].arch_segments_options;
            reason.label = result_idb_required[0].label;
          }
        }

        return reason;
      });
      if (result_idb_required.length === 0) {
        new_request_reason = new_request_reason.filter((r) => r.category !== 'idb_required');
      }
      let props_item_parts_quantity = [];
      const props_item = _.cloneDeep(this.props.item_request_detail).slice(0);

      for (let i = 0; i < props_item.length; i++) {
        if (props_item[i].ireq_id === this.props.item_request.ireq_id) {
          props_item_parts_quantity.push(props_item[i]);
        }
      }

      let state_item_parts_quantity = this.state.item_parts_quantity;
      let save_item_parts_quantity = [];

      const updateItem = (item, singleToothPartId, idbPartId, stateItemPartsQuantity) => {
        if (item.part_id === singleToothPartId) {
          let idb_tray = stateItemPartsQuantity.find((item) => item.part_id === idbPartId);
          if (idb_tray && parseInt(item.quantity) > 0) {
            item.description = idb_tray.description;
            item.part_id = idb_tray.part_id;
            item.quantity = 1;
          }
        }
      };

      const isDe = this.props.item_request.ireq_id.includes('-DE');
      for (let item of props_item_parts_quantity) {
        updateItem(item, '80025', isDe ? '80016' : '80008', state_item_parts_quantity);
        updateItem(item, '80026', isDe ? '80015' : '80007', state_item_parts_quantity);
      }

      for (let i = 0; i < state_item_parts_quantity.length; i++) {
        for (let j = 0; j < props_item_parts_quantity.length; j++) {
          if (props_item_parts_quantity[j].part_id === state_item_parts_quantity[i].part_id) {
            state_item_parts_quantity[i].quantity = parseInt(props_item_parts_quantity[j].quantity);
            state_item_parts_quantity[i].quantity_previous = parseInt(props_item_parts_quantity[j].quantity);
          }
        }
        save_item_parts_quantity.push(state_item_parts_quantity[i]);
      }

      this.setState({
        props_item_parts_quantity: _.cloneDeep(save_item_parts_quantity),
        item_parts_quantity: _.cloneDeep(save_item_parts_quantity),
        props_request_reasons: _.cloneDeep(new_request_reason),
        request_reasons: _.cloneDeep(new_request_reason),
        case_id: this.props.item_request.case_id,
        request_id: this.props.item_request.ireq_id,
        status_comment: this.props.status_comment,
        hide: save_item_parts_quantity.length === 0,
      });

      this.props.history.push({ state: { updateIr: 'false' } });
    }
  }

  redirectUserToCaseDetail = () => {
    this.props.history.push({ state: { refreshInfo: 'true', selectLastestCSR: this.props.mode !== 'update' ? 'true' : 'false' } });
    this.props.onFinish();
  };

  /**
   * Button click event for item request submission on bpp.
   *
   * - Submits the item request to database if all the validation is met.
   * - Used during item request submission or item request update
   * @function onSubmitButtonClick
   * @param {event} event - Button click event.
   */
  onSubmitButtonClick = (event) => {
    const item_request_reasons = this.props.gen_2 ? this.state.request_reasons : clearIdbRequiredReason(this.state.request_reasons);
    if (this.hasReasons() && this.totalPartQuantity()) {
      if (this.hasUpdatesMade()) {
        this.setState({
          request_reasons: item_request_reasons,
        });
        const mode = this.props.mode;
        let ireq_id = '';
        if (this.props.mode === 'update') {
          ireq_id = this.props.item_request.ireq_id;
        }

        let item_part_quantity = this.state.item_parts_quantity;
        let request_reasons = item_request_reasons;
        let case_id = this.state.case_id;
        let use_case_id = this.state.case_id;
        const address_id = this.state.shipping_address_id;
        const status_comment = this.state.status_comment;
        let data = {
          ireq_id,
          mode,
          use_case_id,
          case_id,
          item_part_quantity,
          request_reasons,
          address_id,
          status_comment,
        };
        let that = this;
        this.setState({
          step: 'in_progress',
          buttons: <div />,
        });
        Axios.post(`/apiV2/itemrequest/${case_id}`, data)
          .then(function (res) {
            that.setState({
              step: 'thank_you',
            });

            if (that.props.mode !== 'update') {
              Axios.post(`/api/email/?slug=new-ir-submission-1&ireqId=${res.data.data.item_request_id}&method=standard&provider=${window.location.origin}`);
            }
          })
          .catch(function (err) {
            if (err && err.response && err.response.status === 409) {
              that.setState({
                refresh: true,
              });
            } else {
              that.setState({
                error: true,
              });
            }
          });
      }
    }
  };

  removeWizardErrorMsg() {
    this.setState({
      warning: false,
    });
  }

  show_warning = () => {
    this.setState({
      warning: true,
    });
  };

  getPosition() {
    const position = [{ name: 'item_parts' }, { name: 'item_reason' }, { name: 'item_review' }];

    return position;
  }

  getNextStepperNameByStepperName(stepper_name) {
    const position = this.getPosition();
    let name = '';

    for (let i = 0; i < position.length; i++) {
      if (position[i].name === stepper_name) {
        if (position.length - 1 !== i) {
          name = position[i + 1].name;
        } else {
          name = position[i].name;
        }
        break;
      }
    }

    return name;
  }

  getBackStepperNameByStepperName(stepper_name) {
    const position = this.getPosition();
    let name = '';

    for (let i = 0; i < position.length; i++) {
      if (position[i].name === stepper_name) {
        if (i > 0) {
          name = position[i - 1].name;
        } else {
          name = position[i].name;
        }
        break;
      }
    }

    return name;
  }

  /**
   * Fixes Single Tooth Item Quantities
   * @param {Object} current_selected_idb_reasons
   * @param {Object} current_item_selection
   */
  fixSingleToothQuantity = (current_selected_idb_reasons, current_item_selection) => {
    const upper_part_id = '80025';
    const lower_part_id = '80026';
    const upper_single_tooth_idb = current_item_selection.find((item) => item.part_id === upper_part_id);
    const lower_single_tooth_idb = current_item_selection.find((item) => item.part_id === lower_part_id);
    const selected_teeth = current_selected_idb_reasons.selectedTeeth;
    // Reset the quantity to 0
    if (upper_single_tooth_idb && lower_single_tooth_idb) {
      upper_single_tooth_idb.quantity = 0;
      lower_single_tooth_idb.quantity = 0;

      for (let tooth of selected_teeth) {
        if (tooth.includes('UR') || tooth.includes('UL')) {
          upper_single_tooth_idb.quantity += 1;
        } else if (tooth.includes('LR') || tooth.includes('LL')) {
          lower_single_tooth_idb.quantity += 1;
        }
      }
    }
  };

  hasUpdatesMade = (event) => {
    if (this.props.mode === 'update') {
      let has_different_qty = true;
      let has_different_reason = true;
      const props_request_reasons = this.state.props_request_reasons.slice(0);
      const state_request_reason = this.state.request_reasons.slice(0);
      // Checks Reason and IDB Selections
      state_request_reason.map(function (state_reason) {
        var result = props_request_reasons.filter((r) => r.label === state_reason.label && r.category === state_reason.category);
        if (
          result.length > 0 &&
          (state_reason.booleanValue !== result[0].booleanValue ||
            state_reason.value !== result[0].value ||
            JSON.stringify(state_reason.selectedTeeth) !== JSON.stringify(result[0].selectedTeeth) ||
            (state_reason.category === 'idb_required' &&
              (state_reason.segment_preference !== result[0].segment_preference ||
                state_reason.segment_preference_text !== result[0].segment_preference_text ||
                JSON.stringify(state_reason.arch_segments_options) !== JSON.stringify(result[0].arch_segments_options))))
        ) {
          has_different_reason = false;
          return result;
        }
        return '';
      });
      let comp_item_parts_quantity = this.state.props_item_parts_quantity.slice(0);
      const state_item_parts_quantity = this.state.item_parts_quantity.slice(0);
      const idb_reason = props_request_reasons.find((reason) => reason.category === 'idb_required');
      this.fixSingleToothQuantity(idb_reason, comp_item_parts_quantity);
      // Checks Quantity
      state_item_parts_quantity.forEach((state_item) => {
        let item_present = false;
        comp_item_parts_quantity.forEach((comp_item) => {
          if (comp_item.part_id === state_item.part_id) {
            item_present = true;
          }
        });
        if (!item_present) {
          state_item.quantity = 0;
          comp_item_parts_quantity.push(state_item);
        }
      });
      state_item_parts_quantity.forEach((state_item) => {
        comp_item_parts_quantity.forEach((comp_item) => {
          if (comp_item.part_id === state_item.part_id && parseInt(state_item.quantity) !== parseInt(comp_item.quantity)) {
            has_different_qty = false;
          }
        });
      });

      if (has_different_qty && has_different_reason && this.props.item_request.shipping_address__id === this.state.shipping_address_id) {
        let warning = document.querySelector('#update-warning-submit');
        let warning_text = '<span class="bpp-update-wizard-error-text">No changes have been made</span>';
        warning.classList.add('update-warning-submit');
        warning.innerHTML = warning_text;
        this.setState({
          warning: true,
          error_type: 'Update Item Request',
        });
        return false;
      } else {
        this.setState({
          warning: false,
        });
      }
    }
    return true;
  };

  /**
   * Checks if there are reasons matching quantities for upper or lower arch for IDB request.
   * E.g.: If the user selects Upper Initial IDB Tray and Lower Initial IDB Tray, then there should be a reason for each.
   * @returns {boolean} Returns true if there are matching reasons and quantities; otherwise, false.
   */
  hasReasonsMatchingQuantities = () => {
    const { item_parts_quantity, request_reasons } = this.state;

    const hasQuantityForArch = (arch) =>
      item_parts_quantity.some((part) => part.description.toLowerCase().includes(arch) && part.part_category === KEYWORD_IDB && part.quantity > 0);
    const hasReasonForArch = (arch, keyword) =>
      request_reasons.some((reason) => {
        if (reason.booleanValue && reason.category === REASON_CATEGORY_IDB_REQUIRED) {
          let has_single_tooth_selection = false;
          let has_arch_selection = false;

          if (this.props.gen_2 || reason.segment_preference === 'individual_teeth_segments') {
            has_single_tooth_selection = _.isArray(reason.selectedTeeth) && reason.selectedTeeth.some((tooth) => tooth.toLowerCase().startsWith(keyword));
          }
          if (this.props.gen_2 || reason.segment_preference === 'arch_segments') {
            has_arch_selection = reason.arch_segments_options.some((option) => option.active && option.title.toLowerCase().startsWith(arch));
          }

          return has_single_tooth_selection || has_arch_selection;
        }
        return false;
      });

    const displayWarning = () => {
      let warning = document.querySelector('#warning-submit');
      if (warning) {
        const warningText = '<ul class="bpp-wizard-error-text">IDB Tray</ul>';
        warning.classList.add('warning-display');
        warning.innerHTML = warningText;

        this.setState({
          warning: true,
          error_type: 'Reason for Request',
        });
      }
    };

    try {
      const hasUpperQuantity = hasQuantityForArch(ARCH_UPPER);
      const hasLowerQuantity = hasQuantityForArch(ARCH_LOWER);
      const hasUpperReason = hasReasonForArch(ARCH_UPPER, KEYWORD_UPPER);
      const hasLowerReason = hasReasonForArch(ARCH_LOWER, KEYWORD_LOWER);

      if ((hasUpperQuantity && !hasUpperReason) || (hasLowerQuantity && !hasLowerReason)) {
        displayWarning();
        return false;
      }
    } catch (error) {
      console.error(error);
    }

    return true;
  };

  hasReasons = (event) => {
    let warning = document.querySelector('#warning-submit');
    let warning_text = '<ul class="bpp-list-wizard-error-text">';
    let has_bracket_reason = false;
    let has_smartwire_reason = false;
    let has_idb_reason = false;
    let error = false;
    if (this.state.brackets_has_quantity) {
      this.state.request_reasons.forEach((reason) => {
        if (reason.category === 'brackets') {
          if ((reason.label === 'Other reason:' || reason.label === 'Other reason or additional details:') && !reason.booleanValue && reason.value) {
            reason.booleanValue = true;
          }
        }
      });
      this.state.request_reasons.forEach((reason) => {
        if (reason.category === 'brackets') {
          if (reason.booleanValue) {
            has_bracket_reason = true;
          }
        }
      });
      this.state.request_reasons.forEach((reason) => {
        if (reason.category === 'brackets') {
          if ((reason.label === 'Other reason:' || reason.label === 'Other reason or additional details:') && reason.booleanValue && !reason.value) {
            has_bracket_reason = false;
          }
        }
      });
      if (!has_bracket_reason) {
        error = true;
        warning_text = warning_text + '<li>Brackets</li>';
      }
    }

    if (this.state.idb_has_quantity) {
      this.state.request_reasons.forEach((reason) => {
        if (reason.category === 'idb') {
          if (
            (reason.label === 'Segmented bonding jig needed:' ||
              reason.label === 'Other reason or special requirements:' ||
              reason.label === 'Other reason or additional details:') &&
            !reason.booleanValue &&
            reason.value
          ) {
            reason.booleanValue = true;
          }
        }
      });
      this.state.request_reasons.forEach((reason) => {
        if (reason.category === 'idb') {
          if (reason.booleanValue) {
            has_idb_reason = true;
          }
        }
      });
      this.state.request_reasons.forEach((reason) => {
        if (reason.category === 'idb') {
          if (
            (reason.label === 'Segmented bonding jig needed:' ||
              reason.label === 'Other reason or special requirements:' ||
              reason.label === 'Other reason or additional details:') &&
            reason.booleanValue &&
            !reason.value
          ) {
            has_idb_reason = false;
          }
        }
      });
      this.state.request_reasons.forEach((reason) => {
        if (reason.category === 'idb_required' && !this.props.gen_2) {
          if (reason.segment_preference === 'individual_teeth_segments') {
            if (reason.selectedTeeth.length === 0) {
              has_idb_reason = false;
            }
          } else {
            if (
              reason.arch_segments_options.filter(function (arch_segments_option) {
                return arch_segments_option.active === true;
              }).length === 0
            ) {
              has_idb_reason = false;
            }
          }
        }
      });
      if (!has_idb_reason) {
        error = true;
        warning_text = warning_text + '<li>IDB Tray</li>';
      }
    }

    if (this.state.smartwire_has_quantity) {
      this.state.request_reasons.forEach((reason) => {
        if (reason.category === 'smartwire') {
          if ((reason.label === 'Other reason:' || reason.label === 'Other reason or additional details:') && !reason.booleanValue && reason.value) {
            reason.booleanValue = true;
          }
        }
      });
      this.state.request_reasons.forEach((reason) => {
        if (reason.category === 'smartwire') {
          if (reason.booleanValue) {
            has_smartwire_reason = true;
          }
        }
      });

      this.state.request_reasons.forEach((reason) => {
        if (reason.category === 'smartwire') {
          if ((reason.label === 'Other reason:' || reason.label === 'Other reason or additional details:') && reason.booleanValue && !reason.value) {
            has_smartwire_reason = false;
          }
        }
      });
      if (!has_smartwire_reason) {
        error = true;
        warning_text = warning_text + '<li>Smartwire</li>';
      }
    }

    if (error) {
      if (warning) {
        warning_text = warning_text + '</ul>';
        warning.classList.add('warning-display');
        warning.innerHTML = warning_text;
        this.setState({
          warning: true,
          error_type: 'Reason for Request',
        });
      }
      return false;
    }

    return true;
  };

  totalPartQuantity = (event) => {
    //Fix: const vs. let
    let parts = this.state.item_parts_quantity;
    let qty = 0;
    let brackets_has_quantity = false;
    let smartwire_has_quantity = false;
    let idb_has_quantity = false;
    let brackets_quantity = 0;
    let smartwire_quantity = 0;
    let idb_quantity = 0;

    parts.forEach((part) => {
      qty += part.quantity;
      if (part.part_category === 'bracket') {
        brackets_quantity += part.quantity;
      } else if (part.part_category === 'smartwire') {
        smartwire_quantity += part.quantity;
      } else if (part.part_category === 'idb') {
        idb_quantity += part.quantity;
      }
    });
    brackets_has_quantity = brackets_quantity > 0;
    smartwire_has_quantity = smartwire_quantity > 0;
    idb_has_quantity = idb_quantity > 0;
    if (qty === 0) {
      let warning = document.querySelector('#warning-submit');
      if (warning) {
        warning.classList.add('warning-display');
        warning.innerHTML = '<ul class="bpp-wizard-error-text">Select Item Quantity</ul>';

        this.setState({
          warning: true,
          error_type: 'Request Items',
        });
      }

      return false;
    } else {
      let request_reasons = this.state.request_reasons;
      if (!brackets_has_quantity) {
        request_reasons.forEach((request_reason) => {
          if (request_reason.category === 'brackets') {
            request_reason.booleanValue = false;
            request_reason.value = '';
          }
        });
      }
      if (!smartwire_has_quantity) {
        request_reasons.forEach((request_reason) => {
          if (request_reason.category === 'smartwire') {
            request_reason.booleanValue = false;
            request_reason.value = '';
          }
        });
      }
      if (!idb_has_quantity) {
        request_reasons.forEach((request_reason) => {
          if (request_reason.category === 'idb') {
            request_reason.booleanValue = false;
            request_reason.value = '';
          }
        });
      }
      this.setState({
        brackets_has_quantity: brackets_has_quantity,
        smartwire_has_quantity: smartwire_has_quantity,
        idb_has_quantity: idb_has_quantity,
        request_reason: request_reasons,
      });
    }
    return true;
  };
  onButtonClick = (event) => {
    let item_request_reasons = this.props.gen_2
      ? this.state.request_reasons
      : clearIdbRequiredReasonForArchByQuantity(this.state.item_parts_quantity, this.state.request_reasons);

    event.preventDefault();
    let nextStep = this.getNextStepperNameByStepperName(event.currentTarget.dataset.description);
    let prevStep = this.getBackStepperNameByStepperName(event.currentTarget.dataset.description);
    if (this.state.step === 'item_parts') {
      if (!this.totalPartQuantity()) {
        return;
      } else {
        this.hide_warning();
      }
      this.setState(
        {
          step: 'item_reason',
          original_item_parts_quantity: _.cloneDeep(this.state.item_parts_quantity),
          buttons: (
            <div>
              <BackButton position={'prev'} description={prevStep} onButtonClick={this.onButtonClick} />
              <NextButton position={'next'} description={nextStep} onButtonClick={this.onButtonClick} />
            </div>
          ),
          request_reasons: item_request_reasons,
        },
        scrollToTop
      );
    } else if (this.state.step === 'item_reason') {
      if (event.currentTarget.dataset.position === 'next') {
        let part_list = this.state.item_parts_quantity;
        if (!this.hasReasons() || !this.hasReasonsMatchingQuantities()) {
          return;
        } else {
          this.hide_warning();
        }
        if (!this.state.idb_has_quantity) {
          item_request_reasons = clearIdbSelectedTeeth(this.state.request_reasons);
        }
        if (this.state.idb_has_quantity && this.props.gen_2) {
          part_list = assignIdbPartNumbers(this.state.request_reasons, _.cloneDeep(this.state.item_parts_quantity), this.state.case_id);
        }

        this.setState(
          {
            step: 'item_review',
            item_parts_quantity: part_list,
            buttons: (
              <div>
                <BackButton position={'prev'} description={prevStep} onButtonClick={this.onButtonClick} />
              </div>
            ),
            request_reasons: item_request_reasons,
          },
          scrollToTop
        );
      } else {
        this.hide_warning();
        this.setState(
          {
            step: 'item_parts',
            buttons: (
              <div>
                <NextButton position={'next'} description={nextStep} onButtonClick={this.onButtonClick} />
              </div>
            ),
          },
          scrollToTop
        );
      }
    } else if (this.state.step === 'item_review') {
      if (event.currentTarget.dataset.position === 'prev') {
        this.hide_warning();
        const item_parts_list = this.state.original_item_parts_quantity.length > 0 ? this.state.original_item_parts_quantity : this.state.item_parts_quantity;
        this.setState(
          {
            step: 'item_reason',
            item_parts_quantity: item_parts_list,
            buttons: (
              <div>
                <BackButton position={'prev'} description={prevStep} onButtonClick={this.onButtonClick} />
                <NextButton position={'next'} description={nextStep} onButtonClick={this.onButtonClick} />
              </div>
            ),
          },
          scrollToTop
        );
      }
    }
  };

  setButtons(action) {
    if (action === 'item_parts') {
      this.setState({
        buttons: (
          <div>
            <NextButton position={'next'} onButtonClick={this.onButtonClick} />
          </div>
        ),
      });
    } else if (action === 'item_reason') {
      this.setState({
        buttons: (
          <div>
            <BackButton position={'prev'} onButtonClick={this.onButtonClick} />
            <NextButton position={'next'} onButtonClick={this.onButtonClick} />
          </div>
        ),
      });
    } else if (action === 'item_review') {
      this.setState({
        buttons: (
          <div>
            <BackButton position={'prev'} onButtonClick={this.onButtonClick} />
          </div>
        ),
      });
    }
  }

  hide_warning = () => {
    this.setState({
      warning: false,
    });
  };

  goBack() {
    this.props.history.goBack();
  }

  onRemoveDraft = (event) => {
    this.props.onCancelClick();
  };

  onCaseIdChange = (event) => {
    let item_parts_quantity = this.state.item_parts_quantity;
    item_parts_quantity.forEach((item) => {
      item.quantity = 0;
    });
    this.setState({
      case_id: event.target.value,
      item_parts_quantity: item_parts_quantity,
    });
  };

  onModalDismiss = () => {
    this.setState({
      showCancelCase: false,
    });
  };

  onModalShow = () => {
    this.setState({
      showCancelCase: true,
    });
  };

  reviewInputChange = (event) => {
    this.setState({
      shipping_address_id: parseInt(event.target.dataset.id),
    });
  };

  onComfirmStatusUpdate(status_comment) {
    this.setState({
      status_comment: status_comment.trim(),
    });
  }

  decreaseQuantity = (event) => {
    let item_parts_quantity = this.state.item_parts_quantity;

    if (item_parts_quantity[parseInt(event.target.dataset.id)].quantity > 0) {
      item_parts_quantity[parseInt(event.target.dataset.id)].quantity = item_parts_quantity[parseInt(event.target.dataset.id)].quantity - 1;

      const item_request_reasons = this.props.gen_2
        ? this.state.request_reasons
        : clearIdbRequiredReasonForArchWhenNoQuantity(item_parts_quantity, this.state.request_reasons);

      this.setState({
        item_parts_quantity: item_parts_quantity,
        request_reasons: item_request_reasons,
      });
    }
  };

  increaseQuantity = (event) => {
    this.hide_warning();
    let item_parts_quantity = this.state.item_parts_quantity;
    if (item_parts_quantity[parseInt(event.target.dataset.id)].quantity < item_parts_quantity[parseInt(event.target.dataset.id)].max_qty) {
      item_parts_quantity[parseInt(event.target.dataset.id)].quantity = item_parts_quantity[parseInt(event.target.dataset.id)].quantity + 1;
      this.setState({
        item_parts_quantity: item_parts_quantity,
      });
    }
  };

  onCheckboxClicked = (event) => {
    let id = parseInt(event.target.dataset.id);
    let request_reasons = this.state.request_reasons;
    let request_boolVal = request_reasons.filter(function (reason) {
      return reason.id === id;
    })[0].booleanValue;
    request_reasons.filter(function (reason) {
      return reason.id === id;
    })[0].booleanValue = !request_boolVal;
    this.setState({
      request_reasons: request_reasons,
    });
    this.hide_warning();
  };

  onReasonTextFocus = (event) => {
    let id = parseInt(event.target.dataset.id);
    let reason_text = event.target.value.trim();
    let request_reasons = this.state.request_reasons;
    request_reasons.filter(function (reason) {
      return reason.id === id;
    })[0].value = textFieldLimited(event.target.value.trim());
    if (reason_text) {
      request_reasons.filter(function (reason) {
        return reason.id === id;
      })[0].booleanValue = true;
    } else {
      request_reasons.filter(function (reason) {
        return reason.id === id;
      })[0].booleanValue = false;
    }

    this.setState({
      request_reasons: request_reasons,
    });
    this.hide_warning();
  };

  onEditClick = (event) => {
    let action = event.target.dataset.action;
    this.setState({
      step: action,
    });
    this.setButtons(action);
  };

  setSegmentPreference(request_reason) {
    let id = parseInt(request_reason.id);
    let segment_preference = request_reason.segment_preference;
    let request_reasons = this.state.request_reasons;
    request_reasons.filter(function (reason) {
      return reason.id === id;
    })[0].segment_preference = segment_preference;
    request_reasons.filter(function (reason) {
      return reason.id === id;
    })[0].segment_preference_text = request_reason.segment_preference_text;
    request_reasons.filter(function (reason) {
      return reason.id === id;
    })[0].label = request_reason.segment_preference_text;
    request_reasons.filter(function (reason) {
      return reason.id === id;
    })[0].booleanValue = true;
    this.setState({
      request_reasons: request_reasons,
    });
  }

  /**
   * Checks if Item Request has a Single Tooth Selection
   * @param {json} request_reason
   * @returns {boolean} Single Tooth Selection Status
   */
  checkSingleToothSelection = (request_reason) => {
    if (request_reason.selectedTeeth && request_reason.selectedTeeth.length) {
      return true;
    }
    return false;
  };

  /**
   * Checks if Item Request has an Arch Selection
   * @param {json} request_reason
   * @returns {boolean} Arch Selection Status
   */
  checkArchSelection = (request_reason) => {
    return request_reason?.arch_segments_options?.some((segment) => segment.active);
  };

  /**
   * Checks if Item Request has an Idb Selection
   * @param {json} request_reason
   * @returns {boolean} IDB Selection Status
   */
  checkIdbSelection = (request_reason) => {
    return this.checkArchSelection(request_reason) || this.checkSingleToothSelection(request_reason);
  };

  /**
   * Handles Single Tooth Selection Click
   */
  onhandleTeethClick(request_reason) {
    const idb_reason_id = 799;
    let id = parseInt(request_reason.id);
    let selected_teeth = request_reason.selectedTeeth;
    let request_reasons = _.cloneDeep(this.state.request_reasons);
    const new_reason = request_reasons.find((reason) => reason.id === id);
    new_reason.selectedTeeth = selected_teeth;
    if (id === idb_reason_id) {
      new_reason.single_tooth_selection = this.checkSingleToothSelection(request_reason);
    }
    new_reason.booleanValue = this.checkIdbSelection(request_reason);
    this.setState({
      warning: false,
      request_reasons: request_reasons,
    });
  }

  /**
   * Button click event for selection of archsegment option under idb required reason on BPP.
   *
   * Allows you to select different arch segments that the idb tray is needed for
   * @function setArchSegmentOption
   * @param {json} request_reason - Specific request reason that is being changed from the reason json object.
   */
  setArchSegmentOption(request_reason) {
    let id = parseInt(request_reason.id);
    let arch_segments_options = request_reason.arch_segments_options;
    let request_reasons = _.cloneDeep(this.state.request_reasons);
    const new_reason = request_reasons.find((reason) => reason.id === id);
    new_reason.arch_segments_options = arch_segments_options;
    new_reason.arch_segment_selection = this.checkArchSelection(request_reason);
    new_reason.booleanValue = this.checkIdbSelection(request_reason);
    this.setState({
      request_reasons: request_reasons,
    });
  }

  onReasonTextChange = (event) => {
    let id = parseInt(event.target.dataset.id);
    let reason_text = removeEmoji(event.target.value);
    let request_reasons = this.state.request_reasons;
    request_reasons.filter(function (reason) {
      return reason.id === id;
    })[0].value = removeEmoji(textFieldLimited(event.target.value));
    if (reason_text) {
      request_reasons.filter(function (reason) {
        return reason.id === id;
      })[0].booleanValue = true;
    } else {
      request_reasons.filter(function (reason) {
        return reason.id === id;
      })[0].booleanValue = false;
    }

    this.setState({
      request_reasons: request_reasons,
    });
    this.hide_warning();
  };

  onEditClick = (event) => {
    let action = event.target.dataset.action;
    this.setState({
      step: action,
    });
    this.setButtons(action);
  };

  render() {
    if (this.state.loading) {
      return (
        <div className="page-tab-content">
          <Loader windowSize="ir" />
        </div>
      );
    } else if (this.state.error) {
      return (
        <div className="fullview">
          <Error />
        </div>
      );
    } else {
      return (
        <div className="page-tab-content  bpp-ir-submission">
          <Helmet>
            <title>Item Request Submission | InBrace Smile Design Studio™</title>
          </Helmet>
          <div className="row">
            <div className="col-lg-6" />
            <div className="col-lg-6">
              <div className="pull-right" />
            </div>
          </div>

          <div>
            <div>
              {this.state.step === 'item_parts' ? (
                <ItemQuantity
                  casedetails={this.props.casedetails}
                  mode={this.props.mode}
                  case_id={this.state.case_id}
                  item_parts={this.state.item_parts}
                  item_parts_quantity={this.state.item_parts_quantity}
                  parts_quantity={this.state.parts_quantity}
                  decreaseQuantity={this.decreaseQuantity}
                  increaseQuantity={this.increaseQuantity}
                  goBack={this.onRemoveDraft}
                  onCaseIdChange={this.onCaseIdChange}
                  gen_2={this.props.gen_2}
                />
              ) : null}
              {this.state.step === 'item_reason' ? (
                <ItemReason
                  item_parts={this.state.item_parts}
                  request_reasons={this.state.request_reasons}
                  case_id={this.state.case_id}
                  brackets_has_quantity={this.state.brackets_has_quantity}
                  smartwire_has_quantity={this.state.smartwire_has_quantity}
                  smartwire_other_comments={
                    this.state.request_reasons.filter(function (reason) {
                      return (reason.label === 'Other reason:' || reason.label === 'Other reason or additional details:') && reason.category === 'smartwire';
                    })[0].value
                  }
                  brackets_other_comments={
                    !this.props.gen_2
                      ? this.state.request_reasons.filter(function (reason) {
                          return (reason.label === 'Other reason:' || reason.label === 'Other reason or additional details:') && reason.category === 'brackets';
                        })[0].value
                      : ''
                  }
                  idb_other_comments={
                    this.state.request_reasons.filter(function (reason) {
                      return (
                        (reason.label === 'Segmented bonding jig needed:' ||
                          reason.label === 'Other reason or special requirements:' ||
                          reason.label === 'Other reason or additional details:') &&
                        reason.category === 'idb'
                      );
                    })[0].value
                  }
                  smartwire_other_comments_require={
                    this.state.request_reasons.filter(function (reason) {
                      return (reason.label === 'Other reason:' || reason.label === 'Other reason or additional details:') && reason.category === 'smartwire';
                    })[0].booleanValue
                  }
                  brackets_other_comments_require={
                    !this.props.gen_2
                      ? this.state.request_reasons.filter(function (reason) {
                          return (reason.label === 'Other reason:' || reason.label === 'Other reason or additional details:') && reason.category === 'brackets';
                        })[0].booleanValue
                      : false
                  }
                  idb_other_comments_require={
                    this.state.request_reasons.filter(function (reason) {
                      return (
                        (reason.label === 'Segmented bonding jig needed:' ||
                          reason.label === 'Other reason or special requirements:' ||
                          reason.label === 'Other reason or additional details:') &&
                        reason.category === 'idb'
                      );
                    })[0].booleanValue
                  }
                  idb_has_quantity={this.state.idb_has_quantity}
                  item_parts_quantity={this.state.item_parts_quantity}
                  parts_quantity={this.state.parts_quantity}
                  onCheckboxClicked={this.onCheckboxClicked}
                  onTextChange={this.onReasonTextChange}
                  onFocus={this.onReasonTextFocus}
                  error={this.state.warning}
                  onhandleTeethClick={(teethSet) => {
                    this.hide_warning();
                    this.onhandleTeethClick(teethSet);
                  }}
                  setSegmentPreference={(request_reason) => this.setSegmentPreference(request_reason)}
                  setArchSegmentOption={(request_reason) => {
                    this.hide_warning();
                    this.setArchSegmentOption(request_reason);
                  }}
                  smartwire_breakage_comments={
                    this.props.gen_2
                      ? this.state.request_reasons.filter(function (reason) {
                          return reason.label === 'Wire breakage' && reason.category === 'smartwire';
                        })[0].value
                      : ''
                  }
                  disabled={getDisabledListOfTeethByIDBTray(this.state.item_parts_quantity)}
                  gen_2={this.props.gen_2}
                />
              ) : null}
              {this.state.step === 'thank_you' ? (
                <div className="loader-container-2">
                  <header className="bpp-loader-header">
                    {this.props.mode === 'update' ? (
                      <p className="text-normal">You have successfully updated this item request.</p>
                    ) : (
                      <div>
                        <p className="text-normal">You have successfully submitted the item request</p>
                        <p className="text-normal">
                          A confirmation email has been sent to Doctor {this.props.doctor_first_name + ' ' + this.props.doctor_last_name}
                        </p>
                      </div>
                    )}

                    <button className="btn btn-light" onClick={this.redirectUserToCaseDetail}>
                      Go to Item Request Details
                    </button>
                  </header>
                </div>
              ) : null}
              {this.state.step === 'in_progress' ? <Submitter /> : null}
              {this.state.step === 'item_review' ? (
                <div>
                  <div className="portal-view">
                    <ItemReview
                      case_id={this.state.case_id}
                      mode={this.props.mode}
                      item_parts={this.state.item_parts}
                      addresses={this.state.addresses}
                      address={this.state.address}
                      address_id={this.state.shipping_address_id > 0 ? this.state.shipping_address_id : this.props.item_request.shipping_address__id}
                      item_parts_quantity={this.state.item_parts_quantity}
                      parts_quantity={this.state.parts_quantity}
                      brackets_has_quantity={this.state.brackets_has_quantity}
                      smartwire_has_quantity={this.state.smartwire_has_quantity}
                      smartwire_other_comments={
                        this.state.request_reasons.filter(function (reason) {
                          return (
                            (reason.label === 'Other reason:' || reason.label === 'Other reason or additional details:') && reason.category === 'smartwire'
                          );
                        })[0].value
                      }
                      brackets_other_comments={
                        !this.props.gen_2
                          ? this.state.request_reasons.filter(function (reason) {
                              return (
                                (reason.label === 'Other reason:' || reason.label === 'Other reason or additional details:') && reason.category === 'brackets'
                              );
                            })[0].value
                          : ''
                      }
                      idb_other_comments={
                        this.state.request_reasons.filter(function (reason) {
                          return (
                            (reason.label === 'Segmented bonding jig needed:' ||
                              reason.label === 'Other reason or special requirements:' ||
                              reason.label === 'Other reason or additional details:') &&
                            reason.category === 'idb'
                          );
                        })[0].value
                      }
                      idb_has_quantity={this.state.idb_has_quantity}
                      request_reasons={this.state.request_reasons}
                      onEditClick={this.onEditClick}
                      onInputChange={this.reviewInputChange}
                      status_comment={this.state.status_comment}
                      onChangeEditPhoneType={(index, phoneType) => {
                        this.onChangeEditPhoneType(index, phoneType);
                      }}
                      confirmStatuUpdate={(status_comment) => {
                        this.onComfirmStatusUpdate(status_comment);
                      }}
                      gen_2={this.props.gen_2}
                    />
                    <button
                      className="btn btn-light"
                      style={({ marginLeft: '0px' }, { marginTop: '10px' })}
                      type="button"
                      onClick={this.onSubmitButtonClick}
                      data-case_id={this.state.case_id}
                    >
                      {this.props.mode === 'update' ? 'Update' : 'Submit'} Item Request
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
            {this.state.hide ? null : this.state.buttons}

            <div id="wizard-warning" className={this.state.warning ? 'bpp-wizard-error noselect' : 'bpp-wizard-error wizard-hidden'}>
              <div className="bpp-wizard-error-title">
                <span id="wizard-heading" className="wizard-error-title-main">
                  {this.state.error_type}
                </span>{' '}
                <i className="fa fa-times pull-right pointer" aria-hidden="true" onClick={this.removeWizardErrorMsg} />
              </div>
              <div id={this.props.mode === 'update' && this.state.step === 'item_review' ? 'update-warning-submit' : 'warning-submit'}>Warning</div>
            </div>

            {this.state.showCancelCase === true ? (
              <Modal
                preset="decision"
                header_text="Cancel"
                message_text={
                  <span>
                    Are you sure you want to cancel {this.props.mode === 'update' ? 'updating' : null} this item request? <br /> You will not be able to undo
                    this action if you proceed.
                  </span>
                }
                confirm_btn_text={this.props.mode === 'update' ? 'Cancel Update' : 'Cancel Request'}
                close_btn_text=" Do Not Cancel"
                onConfirmButtonClick={this.onRemoveDraft}
                onCloseButtonClick={this.onModalDismiss}
                theme="bpp"
              />
            ) : null}
            {this.state.step !== 'thank_you' && this.state.step !== 'in_progress' && !this.state.loading && !this.state.error && !this.state.hide ? (
              <div>
                <span className="bpp-ir-cancel-text bpp-ir-cancel-text-icon" onClick={this.onModalShow}>
                  <i className="fa fa-times" aria-hidden="true" />
                </span>
                <span className="bpp-ir-cancel-text" onClick={this.onModalShow}>
                  Cancel
                </span>
              </div>
            ) : null}
          </div>

          {this.state.refresh && <SessionExpire />}
        </div>
      );
    }
  }
}
export default withRouter(IrSubmission);
