export class WindowUtils {
  /**
   * Opens new window with the url
   * @param {String} url
   *
   * @return void
   */
  static openInNewWindow(url) {
    const win = window.open(url, '_blank');

    if (!win) {
      return;
    }
    win.focus();
    win.onblur = () => win.close();
  }
}
