// External Libs
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Internal Libs
import { userHasPermission } from '../../../../common/permission';
import { UserPermissionsContext } from '../../../../context/user_permission';

// Redux Actions
import { openAwbMissingTeethMeasurementsModal, toggleModal } from '../../../../redux/actions/awb';

// Redux Reducers
import { getAwbMissingTeethMeasurementsModal, getAwbMissingTeethMeasurementsModalSelectedTeeth, getAwb } from '../../../../redux/reducers/awb';

class ConvertManual extends Component {
  getTeethInfo() {
    const status = this.props.awb.ireq_automation_status[this.props.awb.ireq_automation_status.length - 1];
    if (status.status_comment) {
      const status_comment = JSON.parse(status.status_comment);
      return {
        missing_teeth: status_comment.missing,
        upper_measurements: status_comment.upper_loop_measurement,
        lower_measurements: status_comment.lower_loop_measurement,
        lower_straight_measurements: status_comment.lower_straight_loop_measurement,
      };
    }
    return {
      missing_teeth: [],
      upper_measurements: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      lower_measurements: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      lower_straight_measurements: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
    };
  }

  render() {
    const teeth_info = this.getTeethInfo();
    const status = this.props.awb.ireq_automation_status[this.props.awb.ireq_automation_status.length - 1];

    return (
      <React.Fragment>
        <div className="process-box-intro">
          <div className="ir process-box-title center-text bold-text">Automated Wire Bending (Manual Process)</div>
          <UserPermissionsContext>
            {(user_roles_and_permissions) => {
              return userHasPermission('IREQ_AWB_MANUAL_CONVERSION', user_roles_and_permissions.permissions) ? (
                <React.Fragment>
                  <div className="center-text">
                    Please complete the fields below. Upon completion, click <span className="bold-text">Convert</span> to begin the conversion process.
                  </div>
                  <div className="extra-padding-headline-bpp" />
                  <div className="center-text">
                    <span className="bold-text">Missing Teeth and IP Loop Measurements:</span>{' '}
                    <span
                      className="underline-text viewable-text"
                      onClick={() =>
                        this.props.openAwbMissingTeethMeasurementsModal(
                          'Complete the fields below.',
                          teeth_info.missing_teeth,
                          teeth_info.upper_measurements,
                          teeth_info.lower_measurements,
                          teeth_info.lower_straight_measurements
                        )
                      }
                    >
                      Specify
                    </span>
                  </div>
                  <div className="extra-padding-headline-bpp" />
                  <div className="center-text">
                    <span className="bold-text">{this.props.selections && this.props.selections.length > 1 ? 'Wires' : 'Wire'}:</span>
                  </div>

                  {/* <div className="extra-padding-headline-bpp" /> */}
                  <div className={'row label-center'}>
                    {this.props.selections && this.props.selections.length > 0
                      ? this.props.selections.map((selection, index) => {
                          return (
                            <div key={index} className={'col-lg-12'}>
                              <input
                                id={selection.parts_id}
                                className={selection.hide ? 'filter-hidden' : 'label-checkbox-ir pointer'}
                                type="checkbox"
                                name="case_labels"
                                value={selection.state}
                                checked={true}
                                disabled={true}
                              />
                              <label className={selection.hide ? 'filter-hidden' : 'label-text-ir'} htmlFor={selection.parts_id}>
                                {' '}
                                {selection.description}
                              </label>
                            </div>
                          );
                        })
                      : null}
                  </div>
                  <div className="extra-padding-headline-bpp" />
                  <div className="button-panel center-text">
                    <UserPermissionsContext>
                      {(user_roles_and_permissions) => {
                        return userHasPermission('IREQ_AWB_MANUAL_CONVERSION', user_roles_and_permissions.permissions) &&
                          this.props.ref_no &&
                          status.status_comment ? (
                          <button type="button" className="btn btn-light" onClick={this.props.openAwbManualConvertModal}>
                            Convert
                          </button>
                        ) : (
                          <button type="button" className="btn btn-light" disabled={true}>
                            Convert
                          </button>
                        );
                      }}
                    </UserPermissionsContext>
                    {this.props.awb.ireq_automation_status.filter(function (w) {
                      return w.status === 'IREQ AWB Automated Process Not Allowed';
                    }).length === 0 ? (
                      <UserPermissionsContext>
                        {(user_roles_and_permissions) => {
                          return userHasPermission('IREQ_AWB_CONVERT', user_roles_and_permissions.permissions) && this.props.ref_no ? (
                            <button type="button" className="btn btn-light" onClick={this.props.openAwbAutomatedConvertModal}>
                              Automated Process
                            </button>
                          ) : (
                            <button type="button" className="btn btn-light" disabled={true}>
                              Automated Process
                            </button>
                          );
                        }}
                      </UserPermissionsContext>
                    ) : null}

                    <UserPermissionsContext>
                      {(user_roles_and_permissions) => {
                        return userHasPermission('IREQ_AWB_OVERRIDE', user_roles_and_permissions.permissions) ? (
                          <button type="button" className="btn btn-light" disabled={!this.props.ref_no} onClick={this.props.openOverrideModal}>
                            Override
                          </button>
                        ) : null;
                      }}
                    </UserPermissionsContext>
                  </div>
                </React.Fragment>
              ) : (
                <div className="center-text">Automated wire bending process in progress...</div>
              );
            }}
          </UserPermissionsContext>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    awb: getAwb(state),
    missing_teeth_measurements_modal: getAwbMissingTeethMeasurementsModal(state),
    awb_missing_teeth_measurements_modal_selected_teeth: getAwbMissingTeethMeasurementsModalSelectedTeeth(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openAwbMissingTeethMeasurementsModal: openAwbMissingTeethMeasurementsModal,
      toggleModal: toggleModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ConvertManual);
