import Axios from 'axios';

/**
 * Opens InBrace Storefront link
 *
 * @function
 * @param {object} doctor - Doctor information
 * @param {function} setShopifyId - State function callback
 */
const openStorefrontLink = (doctor, setShopifyIdCallback) => {
  if (!doctor.shopify_id) {
    createNewShopifyCustomer(doctor, setShopifyIdCallback);
  } else {
    multipassLogin(doctor);
  }
};

/**
 * Adds unsynced addresses to shopify account
 * @param {object} doctor - Doctor Information
 */
const syncAddresses = (doctor) => {
  Axios.post(`/apiv3/shopify/syncaddresses/${doctor.doctor_id}`, {
    account_link_id: doctor.account_link_id,
    shopify_id: doctor.shopify_id,
  })
    .then((res) => {})
    .catch((e) => {
      console.log('error: ', e);
    });
};

/**
 * SSO Login API Call
 *
 * @function
 * @param {object} doctor - Doctor information
 */
const multipassLogin = (doctor) => {
  syncAddresses(doctor);
  Axios.post(`/apiv3/shopify/multipass`, {
    email: doctor.email,
    first_name: doctor.first_name,
    last_name: doctor.last_name,
    account_link_id: doctor.account_link_id,
    shopify_id: doctor.shopify_id,
  })
    .then((res) => {
      window.open(res.data.url, '_blank');
    })
    .catch((e) => {
      window.open(`/portal/${doctor.doctor_id}/storefront_unavailable`, '_blank');
    });
};

/**
 * Create a new Shopify Customer
 *
 * @function
 * @param {object} doctor - Doctor information
 */
const createNewShopifyCustomer = (doctor, setShopifyIdCallback) => {
  Axios.post(`/apiv3/shopify/createcustomer/${doctor.doctor_id}`, {
    email: doctor.email,
    first_name: doctor.first_name,
    last_name: doctor.last_name,
    account_link_id: doctor.account_link_id,
    doctor_role: doctor.role,
  })
    .then((res) => {
      setShopifyIdCallback(res.data.shopify_id);
      multipassLogin({
        ...doctor,
        shopify_id: res.data.shopify_id,
      });
    })
    .catch((e) => {
      window.open(`/portal/${doctor.doctor_id}/storefront_unavailable`, '_blank');
    });
};

export { openStorefrontLink, multipassLogin };
