// External Libs
import React, { Component } from 'react';

// Internal Libs
import { userHasPermission } from '../../../../common/permission';
import { UserPermissionsContext } from '../../../../context/user_permission';

class Fail extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="process-box-intro">
          <div className="ir process-box-title center-text bold-text">Conversion Failure</div>
          <div className="center-text">File conversion failed with following reason</div>
          <div className="center-text bold-text">{this.props.latest_status.status_comment}</div>
          <div className="extra-padding-headline-bpp" />
          <div className="button-panel center-text">
            <UserPermissionsContext>
              {(user_roles_and_permissions) => {
                return userHasPermission('IREQ_AWB_RETRY', user_roles_and_permissions.permissions) && this.props.ref_no ? (
                  <button type="button" className="btn btn-light" onClick={this.props.openRetryModal}>
                    Retry
                  </button>
                ) : null;
              }}
            </UserPermissionsContext>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Fail;
