import './program_agreement.scss';
import React, { Component } from 'react';
import TextBox from '../../doctor/components/inputs/text_box';
import ProgramAgreementText from './program_agreement_text';

/**
 * This component is used to validate a referral code data
 * @component
 * @alias DoctorProgramAgreement
 * @category IPP
 */
class ProgramAgreement extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);

    this.handleKeypress = this.handleKeypress.bind(this);
  }

  /**
   * Email input handel enter
   * @function
   */

  handleKeypress(e) {
    if (e.key === 'Enter' && this.props.agreeTerm1 && this.props.agreeTerm2) {
      this.props.onAccept();
    }
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="agreement_content">
            <ProgramAgreementText />
            <div className="checkbox1">
              <input
                id="terms"
                type="checkbox"
                name="remember"
                onClick={this.props.onAgreeTerm1}
                checked={this.props.agreeTerm1}
                onChange={this.props.onAgreeTerm1}
              />
              <span className="inbrace-text-default checkbox-padding-l">
                I agree to the terms of the InBrace Patient Referral Code Program Services Agreement
              </span>
            </div>
            <div className="checkbox2">
              <input
                id="terms"
                type="checkbox"
                name="remember"
                onClick={this.props.onAgreeTerm2}
                checked={this.props.agreeTerm2}
                onChange={this.props.onAgreeTerm2}
              />
              <span className="inbrace-text-default checkbox-padding-l">I agree to the Business Associate Agreement</span>
            </div>
            <div className="email_input_container">
              <div>
                <p>Please enter your email below so we can email you when a prospective patient signs up for the Patient Referral Program.</p>
              </div>
              <p className="pg_content_light_header">Email*</p>
              <TextBox
                className={this.props.valid_email ? 'ipp_referral_email_input' : 'red_input_boarder ipp_referral_email_input'}
                type="email"
                value={this.props.referall_email}
                onChange={this.props.handleEmailChanges}
                onKeyPress={this.handleKeypress}
                placeholder={'Email'}
                maxLength="100"
              />
              <p className={this.props.valid_email ? 'error-message hide' : 'error-message'}>Please enter a valid email address</p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProgramAgreement;
