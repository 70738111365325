import './ip_upload.scss';

// External Libs
import React from 'react';
import Modal from '../../../../components/modal/modal';
import ProcessComponent from '../../../../components/workflow/process_component';
// Internal Libs
import Uploader from '../../../../components/modal/uploader';

import { userHasPermission } from '../../../../common/permission';
import { removeCaseIdInitialNumber } from '../../../../common/functions';
import { UserPermissionsContext } from '../../../../context/user_permission';

/**
 * Displays Automated wirebending status step
 * @component
 * @alias IPStatus
 * @category BPP
 */
class IPStatus extends ProcessComponent {
  constructor(props) {
    super(props);

    const isDE = this.props.case_id.indexOf('-') >= 0 ? true : false;

    this.state = {
      upper_selection: isDE ? '16' : 'both',
      lower_selection: isDE ? '16' : 'both',
      convert_file_modal: false,
      remove_file_modal: false,
      conversion_failure: false,
      conversion_failure_message: 'Unable to convert',
      override_modal: false,
      disable_convert_button: true,
      isDE: isDE,
      conversion_processing: false,
    };
  }

  componentDidMount() {
    this.detectUploadFile();

    // Set upper and lower selections to be the same as previous selection (if applicable)
    let logs = this.props.ip_process.log.filter((log) => {
      return log.status_code === 'Recipe Conversion Process';
    });

    if (logs.length > 0) {
      let status_comment = JSON.parse(logs[logs.length - 1].text);

      if (this.props.txplan_arch === 'upper' && status_comment.upper === 'none') {
        this.setState({
          upper_selection: this.state.isDE ? '16' : 'both',
        });
      } else if (this.props.txplan_arch === 'lower' && status_comment.lower === 'none') {
        this.setState({
          lower_selection: this.state.isDE ? '16' : 'both',
        });
      } else {
        this.setState({
          upper_selection: status_comment.upper,
          lower_selection: status_comment.lower,
        });
      }
    }
  }

  getCaseStatus = () => {
    return this.props.case_status;
  };

  onUpload = (data) => {
    let that = this;

    this.taskRunner('onIpUpload', data).then((result) => {
      that.setState({
        disable_convert_button: false,
      });
    });
  };

  onConfirmRemoveClick = (event) => {
    let that = this;

    this.taskRunner('onRemove', event).then((result) => {
      that.setState({ remove_file_modal: false, disable_convert_button: true });
    });
  };

  onConfirmOverrideClick = (event) => {
    let that = this;
    this.taskRunner('onOverrideProcess', event).then((result) => {
      if (result) {
        this.props.reload_information();
      }

      that.setState({ override_modal: false });
    });
  };

  onConvertClick = (event) => {
    event.preventDefault();
    this.toggleUploadModal();
  };

  onOverrideClick = (event) => {
    event.preventDefault();
    this.toggleOverrideModal();
  };

  onRemoveClick = (event) => {
    event.preventDefault();
    this.toggleRemoveModal();
  };

  onRetryClick = (event) => {
    this.setState({
      conversion_failure: false,
      conversion_failure_message: '',
    });
  };

  toggleUploadModal = () => {
    this.setState({ convert_file_modal: !this.state.convert_file_modal });
  };

  toggleOverrideModal = () => {
    this.setState({ override_modal: !this.state.override_modal });
  };

  toggleRemoveModal = () => {
    this.setState({ remove_file_modal: !this.state.remove_file_modal });
  };

  onUpperSelection = (event) => {
    this.setState({
      upper_selection: event.target.value,
    });
  };

  onLowerSelection = (event) => {
    this.setState({
      lower_selection: event.target.value,
    });
  };

  onProceedFromUploadModal = () => {
    const header_text = `Convert File - ${removeCaseIdInitialNumber(this.props.case_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you want to convert this file?"
        confirm_btn_text="Convert"
        onConfirmButtonClick={this.onConfirmUploadClick}
        close_btn_text="Cancel"
        onCloseButtonClick={this.onConvertClick}
      />
    );
  };

  onRemoveFileModal = () => {
    const header_text = `Remove File - ${removeCaseIdInitialNumber(this.props.case_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you want to remove this file?"
        confirm_btn_text="Remove"
        onConfirmButtonClick={this.onConfirmRemoveClick}
        close_btn_text="Cancel"
        onCloseButtonClick={this.onRemoveClick}
      />
    );
  };

  /**
   * Starts the conversion process for AWB
   * @function
   * @param {Object} event - Button click event
   */
  onConfirmUploadClick = (event) => {
    let that = this;

    const data = {
      event: event,
      upper_selection: this.props.txplan_arch.toLowerCase() !== 'lower' ? this.state.upper_selection : 'none',
      lower_selection: this.props.txplan_arch.toLowerCase() !== 'upper' ? this.state.lower_selection : 'none',
    };
    that.setState({ convert_file_modal: false, conversion_processing: true });
    this.taskRunner('onStartConversion', data).then((result) => {
      if (result) {
        this.props.reload_information();
      }
    });
  };

  onOverrideModal = () => {
    const header_text = `Override Process - ${removeCaseIdInitialNumber(this.props.case_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you would like to override this process?"
        confirm_btn_text="Override"
        onConfirmButtonClick={this.onConfirmOverrideClick}
        close_btn_text="Cancel"
        onCloseButtonClick={this.onOverrideClick}
      />
    );
  };

  detectUploadFile = () => {
    if (this.props.ip_files.length > 0) {
      this.setState({
        disable_convert_button: false,
      });
    } else {
      this.setState({
        disable_convert_button: true,
      });
    }
  };

  render() {
    return (
      <div className="process_tracker">
        <div className={'margin-top-20'}>
          <hr className={'hidden'} />
          <div className="cad-window">
            {!(this.props.case_status === 'STATUS_HOLD' || this.props.case_status === 'STATUS_CANCEL') ? (
              <UserPermissionsContext>
                {(user_roles_and_permissions) => {
                  return userHasPermission('IPLOOP_UPLOAD', user_roles_and_permissions.permissions) ? (
                    this.state.conversion_processing ? (
                      <React.Fragment>
                        <div className="process-box-intro">
                          <div className="process-box-title">Conversion</div>
                          <div>Conversion in Progress...</div>
                        </div>
                        <div className="row text-center">
                          <i className="fa fa-refresh font-size-xlg rotate" aria-hidden="true" />
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className="paragraph">
                          <div>
                            <b>Upload</b>
                          </div>
                          Upload the 3Matic outputs (Bracket Measurement file) or the IP Loop Worksheet and select the required wires for the case. Upon
                          successful upload, click <span className="bold-text">Convert</span> to begin the conversion process.
                        </div>

                        <div className="ip-worksheet-zone">
                          {!userHasPermission('IPLOOP_UPLOAD', this.props.user_roles_and_permissions.permissions) &&
                          this.props.ip_files &&
                          this.props.ip_files.filter((files) => {
                            return files.file_type === 'ip_upload' && files.upload_data.includes('IP_UPLOAD');
                          }).length === 0 ? (
                            <div className="ip-worksheet-upload-disabled">
                              <div className="font-size-reg">Drag and drop or click here to upload file</div>
                              <i className="fa fa-upload font-size-lg" aria-hidden="true" />
                            </div>
                          ) : (
                            <Uploader
                              className="ip-worksheet-upload"
                              id={this.props.case_id}
                              photoUpload={
                                this.props.ip_files && this.props.ip_files.length > 0
                                  ? this.props.ip_files.filter((files) => {
                                      return files.file_type === 'ip_upload' && files.upload_data.includes('IP_UPLOAD');
                                    })
                                  : []
                              }
                              onUpload={this.onUpload}
                              onRemove={this.onRemoveClick}
                              folder="ip_upload"
                              type="ip_upload"
                              loader_type="bpp"
                              location="complete"
                              isUploading={this.props.isUploading}
                              remove_btn={userHasPermission('IPLOOP_DELETE', this.props.user_roles_and_permissions.permissions) ? true : false}
                            />
                          )}
                        </div>

                        <div className="ip-worksheet-wire">
                          {this.props.txplan_arch === 'both' || this.props.txplan_arch === 'upper' ? (
                            <div className="">
                              <div className="bold-text select-label select-label-inline">Upper Wire:</div>
                              {userHasPermission('IPLOOP_UPLOAD', this.props.user_roles_and_permissions.permissions) ? (
                                <div className="select-container">
                                  <select className="select-status select-wire" value={this.state.upper_selection} onChange={this.onUpperSelection}>
                                    <option value="both">.016 and .014</option>
                                    <option value="16">.016 only</option>
                                    <option value="14">.014 only</option>
                                    {this.props.txplan_arch === 'upper' ? null : <option value="none">None</option>}
                                  </select>
                                </div>
                              ) : (
                                <div className="select-wire-readonly">
                                  {this.state.upper_selection === 'both' ? '.016 and .014' : null}
                                  {this.state.upper_selection === '16' ? '.016 only' : null}
                                  {this.state.upper_selection === '14' ? '.014 only' : null}
                                  {this.state.upper_selection === 'none' ? 'None' : null}
                                </div>
                              )}
                            </div>
                          ) : null}

                          {this.props.txplan_arch === 'both' || this.props.txplan_arch === 'lower' ? (
                            <div className="">
                              <div className="bold-text select-label select-label-inline">Lower Wire:</div>
                              {userHasPermission('IPLOOP_UPLOAD', this.props.user_roles_and_permissions.permissions) ? (
                                <div className="select-container">
                                  <select className="select-status select-wire" value={this.state.lower_selection} onChange={this.onLowerSelection}>
                                    <option value="both">.016 (Lower/Lower St) and .014</option>
                                    <option value="16">.016 only (Lower/Lower St)</option>
                                    <option value="14">.014 only</option>
                                    {this.props.txplan_arch === 'lower' ? null : <option value="none">None</option>}
                                  </select>
                                </div>
                              ) : (
                                <div className="select-wire-readonly">
                                  {this.state.lower_selection === 'both' ? '.016 (Lower/Lower St) and .014' : null}
                                  {this.state.lower_selection === '16' ? '.016 only (Lower/Lower St)' : null}
                                  {this.state.lower_selection === '14' ? '.014 only' : null}
                                  {this.state.lower_selection === 'none' ? 'None' : null}
                                </div>
                              )}
                            </div>
                          ) : null}
                          {(this.props.txplan_arch === 'both' && this.state.upper_selection === 'none' && this.state.lower_selection === 'none') ||
                          (this.props.txplan_arch === 'lower' && this.state.lower_selection === 'none') ||
                          (this.props.txplan_arch === 'upper' && this.state.upper_selection === 'none') ? (
                            <div className="ip-warning select-label-inline">Please select at least 1 wire type</div>
                          ) : null}
                        </div>
                        <div className="ip-actions">
                          {userHasPermission('IPLOOP_UPLOAD', this.props.user_roles_and_permissions.permissions) ? (
                            <button
                              type="button"
                              className="btn btn-light"
                              onClick={this.onConvertClick}
                              onDoubleClick={this.onConvertClick}
                              disabled={
                                (this.props.txplan_arch === 'both' && this.state.upper_selection === 'none' && this.state.lower_selection === 'none') ||
                                (this.props.txplan_arch === 'lower' && this.state.lower_selection === 'none') ||
                                (this.props.txplan_arch === 'upper' && this.state.upper_selection === 'none') ||
                                (this.state.upper_selection === 'none' && this.state.lower_selection === 'none') ||
                                this.state.disable_convert_button
                              }
                            >
                              Convert
                            </button>
                          ) : null}
                          {userHasPermission('IPLOOP_OVERRIDE', this.props.user_roles_and_permissions.permissions) ? (
                            <button type="button" className="btn btn-light" onClick={this.onOverrideClick}>
                              Override
                            </button>
                          ) : null}
                        </div>
                      </React.Fragment>
                    )
                  ) : (
                    <React.Fragment>
                      <div className="paragraph">
                        <div>
                          <b>Automated Wire Bending</b>
                        </div>
                      </div>
                      <div className="center-text">Automated wire bending process in progress...</div>
                    </React.Fragment>
                  );
                }}
              </UserPermissionsContext>
            ) : null}
            {this.state.remove_file_modal ? this.onRemoveFileModal() : null}
            {this.state.convert_file_modal ? this.onProceedFromUploadModal() : null}
            {this.state.override_modal ? this.onOverrideModal() : null}
          </div>
        </div>
      </div>
    );
  }
}

export default IPStatus;
