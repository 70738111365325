import Axios from 'axios';
import React, { Component } from 'react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, NavLink, Link } from 'react-router-dom';

import Badge from '../components/badge';
import Gen1AlertModal from '../../components/modal/gen_1_alert_modal';
import HamburgerMenu from './hamburger_menu';
import Logout from '../logout/logout';
import NotFound from '../404/not_found';
import { ReactComponent as InLogo } from './in.svg';
import { SDS } from '../assets/logos';
import { UserPermissionsContext } from '../../context/user_permission';
import { drawProfilePicture } from '../../common/functions';
import { fetchProfilePicture } from '../../redux/actions/ipp/account_settings';
import { getDoctorsIdFromRoute } from '../../common/route';
import { getProfileImage, getProfileMetadata } from '../../redux/reducers/ipp/account_settings';
import { getLoggedDoctorCaseCount } from '../../redux/reducers/ipp/case_list/case_list_filter';
import { fetchLoggedDoctorCaseCount } from '../../redux/actions/ipp/case_list/case_list_filter';
import { openStorefrontLink, multipassLogin } from '../../common/shopify';
import { scrollToTop } from '../../common/scroll';
import { getShowHelpSidebar } from '../../redux/reducers/ipp/help_sidebar';
import { toggleHelpSidebar, closeHelpSidebar, openHelpSidebar } from '../../redux/actions/ipp/help_sidebar';
import { userHasPermission, userHasPermissions } from '../../common/permission';

export const NAVBAR_HEIGHT = 55;
/**
 * Displays the navbar and sidebar
 * @component
 */
class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideBarOpen: false,
      loading: true,
      doctor_id: null,
      user_id: '',
      shopify_id: '',
      role: '',
      case_action_count: 0,
      ir_action_count: 0,
      prospect_action_count: 0,
      smile_simulation_count: 0,
      email: '',
      first_name: '',
      last_name: '',
      account_link_id: '',
      permission_roles: ['Doctor'],
      profile_picture_width_in_px: 42,
      refresh: false,
      gen2: false,
      dso_admin_smile_permission: false,
      not_found: false,
      shopify_enabled: false,
      gen_1_alert: false,
      program_enrollment: [],
    };

    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    const that = this;
    const doctor_id = getDoctorsIdFromRoute();
    if (doctor_id) {
      this.props.fetchLoggedDoctorCaseCount({ doctorId: doctor_id });
      Axios.get(`/apiV2/account_light/${doctor_id}`).then(function (doctor_res) {
        if (doctor_res.data && doctor_res.data.account_id) {
          const is_edit_dso_doctor_account_page = false;
          const open_reposition_modal = false;
          that.props.fetchProfilePicture(doctor_id, is_edit_dso_doctor_account_page, open_reposition_modal, that);
          that.setState({
            doctor_id: doctor_res.data.account_id,
            first_name: doctor_res.data.first_name,
            last_name: doctor_res.data.last_name,
            email: doctor_res.data.account_email,
            shopify_id: doctor_res.data.shopify_id,
            doctor_role: doctor_res.data.role,
            account_link_id: doctor_res.data.account_link_id,
            permission_roles: doctor_res.data.permission_roles,
            case_action_count: doctor_res.data.case_action_count,
            ir_action_count: doctor_res.data.ir_action_count,
            prospect_action_count: doctor_res.data.prospect_action_count,
            smile_action_count: doctor_res.data.smile_action_count,
            smile_simulation_count: doctor_res.data.smile_simulation_count,
            gen2: doctor_res.data.gen2,
            dso_admin_smile_permission: doctor_res.data.dso_admin_smile_permission,
            loading: false,
            program_enrollment: doctor_res.data.program_enrollment,
          });

          if (
            that.urlContains('', '?filter=smile_simulation', 'SEARCH_ONLY') &&
            (!doctor_res.data.dso_admin_smile_permission || (!that.userHasSmileView() && doctor_res.data.smile_simulation_count === 0))
          ) {
            that.setState({ not_found: true });
            return;
          }
        } else {
          Axios.get(`/apiV2/account_light`).then(function (res) {
            that.setState({
              doctor_id: res.data.account_id,
              shopify_id: res.data.shopify_id,
              first_name: res.data.first_name,
              last_name: res.data.last_name,
              email: res.data.account_email,
              doctor_role: res.data.role,
              account_link_id: res.data.account_link_id,
              permission_roles: res.data.permission_roles,
              gen2: res.data.gen2,
              loading: false,
            });
          });
        }
      });
      Axios.get(`/apiv3/shopify/doctorenable/${doctor_id}`).then((res) => {
        if (res.data.enabled) {
          that.setState({ shopify_enabled: true });
        }
      });
    }

    Axios.get(`/apiV2/account_light`).then(function (res) {
      if (res.data && res.data.role !== 'Business') {
        that.props.fetchProfilePicture(res.data.account_id, false, false, that);
        that.setState({
          doctor_id: res.data.account_id,
          first_name: res.data.first_name,
          last_name: res.data.last_name,
          doctor_role: res.data.role,
          account_link_id: res.data.account_link_id,
          permission_roles: res.data.permission_roles,
          dso_admin_smile_permission: res.data.dso_admin_smile_permission,
          case_action_count: res.data.case_action_count || 0,
          ir_action_count: res.data.ir_action_count || 0,
          prospect_action_count: res.data.prospect_action_count || 0,
          smile_action_count: res.data.smile_action_count || 0,
          smile_simulation_count: res.data.smile_simulation_count || 0,
        });
      } else {
        that.setState({
          bpp_first_name: res.data.first_name,
          bpp_last_name: res.data.last_name,
        });
      }
      that.setState({
        role: res.data.role,
        user_id: res.data.account_id,
        loading: false,
      });
    });
  }

  componentDidUpdate(prevProps) {
    const { profile_image, profile_metadata } = this.props;
    const isProfileUpdated = profile_image !== prevProps.profile_image || profile_metadata !== prevProps.profile_metadata;

    if (isProfileUpdated) {
      const isEditDSODoctorAccountPage = false;
      drawProfilePicture(this, isEditDSODoctorAccountPage);
    }

    this.refreshCount();
  }
  /**
   * Refreshes the count based on the current route and state.
   */
  refreshCount = () => {
    if (this.props.history?.location?.state?.refreshInfo || this.state.refresh) {
      let that = this;
      let refresh = this.state.refresh;
      const doctor_id = getDoctorsIdFromRoute();

      let url = `/apiV2/account_light/`;
      if (doctor_id) {
        url = `/apiV2/account_light/${doctor_id}`;
      }

      Axios.get(url).then(function (res) {
        if (res.data && res.data.account_id && res.data.role !== 'Business') {
          that.setState({
            case_action_count: res.data.case_action_count,
            ir_action_count: res.data.ir_action_count,
            prospect_action_count: res.data.prospect_action_count,
            smile_action_count: res.data.smile_action_count,
            smile_simulation_count: res.data.smile_simulation_count,
            refresh: false,
          });

          if (!refresh) {
            that.props.history.replace({ state: undefined });
          }
        }
      });
    }
  };

  userHasProspectView = () => {
    return _.intersection(['Doctor-Prospect'], this.state.permission_roles).length > 0;
  };

  userHasSmileView = () => {
    return _.intersection(['Doctor-SmileSimulation'], this.state.permission_roles).length > 0;
  };

  scrollToNavbar = () => {
    document.querySelector('.navbar').scrollIntoView({ behavior: 'smooth' });
  };

  toggleSideBar = () => {
    this.setState((prevstate) => {
      if (!prevstate.sideBarOpen) {
        this.scrollToNavbar();
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
      return { sideBarOpen: !prevstate.sideBarOpen };
    });
  };
  /**
   * Sets the side bar state to be closed and refresh the count value
   * @function
   */
  closeSideBar = () => {
    document.body.classList.remove('no-scroll');
    this.setState({ sideBarOpen: false, refresh: true }, scrollToTop);
  };

  urlContains = (pathname, search, conditional) => {
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.pathname !== undefined &&
      this.props.history.location.search !== undefined
    ) {
      const containsPathname = this.props.history.location.pathname.indexOf(pathname) >= 0;
      const containsSearch =
        conditional && conditional === 'AND' && search === ''
          ? search === this.props.history.location.search
          : this.props.history.location.search.indexOf(search) >= 0;

      if (conditional === 'AND') {
        return containsPathname && containsSearch;
      } else if (conditional === 'SEARCH_ONLY') {
        return containsSearch;
      } else if (conditional === 'PATHNAME_ONLY') {
        return containsPathname;
      }
    }

    return false;
  };

  allowedToSeeWhileOnKnowledgeBase = () => {
    const knowledgebase_url = '/portal/knowledgebase';
    const is_knowledgebase_url = this.urlContains(knowledgebase_url, '', 'PATHNAME_ONLY');

    if ((is_knowledgebase_url && this.isDoctorOrDsoAccount()) || !is_knowledgebase_url) {
      return true;
    }

    return false;
  };

  isDoctorOrDsoAccount = () => {
    const user_role = this.state.role;

    if (user_role) {
      return user_role.indexOf('Doctor') >= 0 || user_role.indexOf('DSO') >= 0;
    }

    return false;
  };

  getDoctorId = () => {
    return getDoctorsIdFromRoute() ? getDoctorsIdFromRoute() : this.state.doctor_id;
  };

  getNavBarContent = (permissions) => {
    const doctor_id = this.getDoctorId();

    return [
      {
        logo: <FontAwesomeIcon icon={['fas', 'home']} />,
        label: 'Dashboard',
        link: `/portal/${doctor_id}/dashboard`,
        display: this.allowedToSeeWhileOnKnowledgeBase(),
        active: () => this.urlContains(`/portal/${doctor_id}/dashboard`, '', 'PATHNAME_ONLY'),
      },
      {
        logo: <FontAwesomeIcon icon={['fas', 'folder-plus']} />,
        label: 'Submit Case',
        link: this.state.gen2 ? `/portal/${doctor_id}/submission/patient` : '',
        display: userHasPermission('IPP_EDIT', permissions) && this.allowedToSeeWhileOnKnowledgeBase(),
        showLogoInNavBar: true,
        active: () => this.urlContains(`/portal/${doctor_id}/submission/`, '', 'PATHNAME_ONLY'),
        disabled: !this.state.gen2,
        disabledCallback: () => this.setState({ gen_1_alert: true }),
      },
      {
        logo: <FontAwesomeIcon icon={['fas', 'folder']} />,
        label: 'Cases',
        link: `/portal/${doctor_id}`,
        browser_state: { reset_list: 'true' },
        display: this.allowedToSeeWhileOnKnowledgeBase(),
        active: () => {
          return (
            this.urlContains(`/portal/${doctor_id}`, '', 'AND') &&
            !this.urlContains(`/portal/${doctor_id}/submission/`, '', 'PATHNAME_ONLY') &&
            !this.urlContains(`/portal/${doctor_id}/accounts`, '', 'PATHNAME_ONLY') && // dso admin view
            !this.urlContains(`/portal/${doctor_id}/account`, '', 'PATHNAME_ONLY') && // doctors account
            !this.urlContains(`/portal/${doctor_id}/case/`, '', 'PATHNAME_ONLY') &&
            !this.urlContains(`/portal/${doctor_id}/item/`, '', 'PATHNAME_ONLY') &&
            !this.urlContains(`/portal/${doctor_id}/note/`, '', 'PATHNAME_ONLY') &&
            !this.urlContains(`/portal/${doctor_id}/progress/`, '', 'PATHNAME_ONLY') &&
            !this.urlContains(`/portal/${doctor_id}/prospect`, '', 'PATHNAME_ONLY') &&
            !this.urlContains(`/portal/${doctor_id}/dashboard`, '', 'PATHNAME_ONLY') &&
            !this.urlContains(`/portal/${doctor_id}/smile/`, '', 'PATHNAME_ONLY') &&
            !this.urlContains(`/portal/${doctor_id}/tier-details`, '', 'PATHNAME_ONLY') &&
            !this.urlContains(`/portal/${doctor_id}/programs`, '', 'PATHNAME_ONLY')
          );
        },
        count: this.props.loggedDoctorCaseCount.action ? this.props.loggedDoctorCaseCount.action : 0,
      },
      {
        logo: <FontAwesomeIcon icon={['fas', 'box-open']} />,
        label: 'Item Requests',
        link: `/portal/${doctor_id}`,
        browser_search: `?filter=item_request`,
        browser_state: { reset_list: 'true', filter: 'item_request' },
        display: this.allowedToSeeWhileOnKnowledgeBase(),
        active: () => this.urlContains('', '?filter=item_request', 'SEARCH_ONLY'),
        count: this.state.ir_action_count ? this.state.ir_action_count : 0,
      },
      {
        logo: <FontAwesomeIcon icon={['fas', 'tooth']} />,
        label: 'Smile Simulation',
        link: `/portal/${doctor_id}`,
        browser_search: `?filter=smile_simulation`,
        display:
          (this.userHasSmileView() || this.state.smile_simulation_count > 0) &&
          this.allowedToSeeWhileOnKnowledgeBase() &&
          this.state.dso_admin_smile_permission,
        browser_state: { reset_list: 'true', filter: 'smile_simulation' },
        active: () => this.urlContains('', '?filter=smile_simulation', 'SEARCH_ONLY'),
        count: this.state.smile_action_count ? this.state.smile_action_count : 0,
      },
      {
        logo: <FontAwesomeIcon icon={['fas', 'portrait']} />,
        label: 'Prospects',
        link: `/portal/${doctor_id}`,
        browser_search: `?filter=prospect_list`,
        browser_state: { reset_list: 'true', filter: 'prospect_list' },
        display: userHasPermissions(['IPP_PROSPECT'], permissions) && this.userHasProspectView() && this.allowedToSeeWhileOnKnowledgeBase(),
        active: () => this.urlContains('', '?filter=prospect_list', 'SEARCH_ONLY'),
        count: this.state.prospect_action_count ? this.state.prospect_action_count : 0,
      },
      {
        logo: <FontAwesomeIcon icon={['fas', 'question-circle']} />,
        label: 'Knowledge Base',
        link: '/portal/knowledgebase',
        display: userHasPermissions(['KNOWLEDGEBASE_VIEW', 'IPP_VIEW'], permissions) && this.allowedToSeeWhileOnKnowledgeBase(),
        active: () => this.urlContains('/portal/knowledgebase', '', 'PATHNAME_ONLY'),
      },
      {
        logo: <FontAwesomeIcon icon={['fas', 'store']} />,
        label: 'Storefront',
        browser_state: { reset_list: 'true' },
        display: !this.urlContains('/portal/knowledgebase', '', 'PATHNAME_ONLY') && this.state.shopify_enabled,
      },
    ];
  };

  shouldDisplayBPPDropdownItems() {
    return this.state.role.indexOf('Business') >= 0 || this.state.role.indexOf('Admin') >= 0;
  }

  getDropdownContent(user_roles_and_permissions) {
    const doctor_id = this.getDoctorId();

    return [
      {
        section: 'settings',
        logo: <FontAwesomeIcon icon={['fas', 'cog']} />,
        label: 'Smile Design Preferences',
        link: `/portal/${doctor_id}/account/smile-design-preferences`,
        display:
          (userHasPermission('IPP_EDIT', user_roles_and_permissions.permissions) || userHasPermission('IPP_VIEW', user_roles_and_permissions.permissions)) &&
          this.hasProgramEnrollment('smile_design_preferences') &&
          this.state.doctor_role !== 'DSO',
        active: () => this.urlContains(`/portal/${doctor_id}/account/smile-design-preferences`, '', 'PATHNAME_ONLY'),
      },
      {
        section: 'settings',
        logo: <FontAwesomeIcon icon={['fas', 'cog']} />,
        label: 'General Settings',
        link: `/portal/${doctor_id}/account/settings`,
        display: true,
        active: () => this.urlContains(`/portal/${doctor_id}/account/settings`, '', 'PATHNAME_ONLY'),
      },
      {
        section: 'settings',
        logo: <FontAwesomeIcon icon={['fas', 'user-circle']} />,
        label: 'Accounts',
        link: `/portal/${doctor_id}/accounts`,
        display: userHasPermission('DSO_ADMIN', user_roles_and_permissions.permissions) && this.state.doctor_role === 'DSO',
        active: () => this.urlContains(`/portal/${doctor_id}/account/`, '', 'PATHNAME_ONLY'),
      },
      {
        section: 'settings',
        logo: <FontAwesomeIcon icon={['fas', 'clipboard-list']} />,
        label: 'Programs',
        link: `/portal/${doctor_id}/programs`,
        display: this.state.doctor_role && !this.state.doctor_role.includes('DSO'),
        active: () => this.urlContains(`/portal/${doctor_id}/programs`, '', 'PATHNAME_ONLY'),
      },
      {
        section: 'bpp',
        logo: <FontAwesomeIcon icon={['fas', 'building']} />,
        label: 'Return to BPP',
        link: '/business/portal',
        display: this.shouldDisplayBPPDropdownItems(),
      },
      {
        logo: <FontAwesomeIcon icon={['fas', 'question-circle']} />,
        label: 'Help',
        display: this.state.sideBarOpen,
        onlyForSidebar: true,
        active: false,
        props: {
          onClick: () => this.props.openHelpSidebar(),
        },
      },
      {
        section: 'logout',
        logo: <FontAwesomeIcon icon={['fas', 'sign-out-alt']} />,
        label: 'Logout',
        component: Logout,
        props: { type: 'link', name: 'Log Out' },
        display: true,
      },
    ];
  }

  getDropdownContentItemsForDesktop(dropdownContent, filter) {
    return dropdownContent
      .filter((item) => item.section === filter && item.display && !item.onlyForSidebar)
      .map((item) => {
        return <li key={item.label}>{item.component ? <item.component {...item.props} /> : <Link to={item.link}>{item.label}</Link>}</li>;
      });
  }

  displayAccountDropdown(dropdownContent) {
    if (!this.allowedToSeeWhileOnKnowledgeBase()) return;

    const handleClickHelp = () => {
      if (!this.props.showHelpSidebar) {
        this.scrollToNavbar();
      }
      this.props.toggleHelpSidebar();
    };

    return (
      <div className="navbar__content navbar__content--right">
        <div className="navbar__menu__item navbar__menu__item-help-sidebar" role="button" onClick={handleClickHelp}>
          Help
        </div>
        <div className="dropdown navbar__menu-item-account" onClick={() => this.props.closeHelpSidebar()}>
          <div className="navbar__menu__item" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
            <span className="navbar__menu__item__label">
              {this.state.doctor_role === 'DSO' ? `${this.state.first_name} ` : this.state.last_name ? `Dr. ${this.state.last_name} ` : ''}
            </span>
            <div className="navbar__menu__image">
              {this.props.profile_image ? (
                <canvas width={this.state.profile_picture_width_in_px} height={this.state.profile_picture_width_in_px} ref={this.canvasRef}></canvas>
              ) : this.state.first_name ? (
                <FontAwesomeIcon icon={['fas', 'user-circle']} />
              ) : null}
            </div>
          </div>

          <ul className="dropdown-menu dropdown-menu-right">
            <li className="dropdown-header">SETTINGS</li>
            {this.getDropdownContentItemsForDesktop(dropdownContent, 'settings')}
            <li role="separator" className="divider"></li>
            {this.shouldDisplayBPPDropdownItems() ? (
              <>
                <li className="dropdown-header">BPP</li>
                {this.getDropdownContentItemsForDesktop(dropdownContent, 'bpp')}
                <li role="separator" className="divider"></li>
              </>
            ) : null}
            {this.getDropdownContentItemsForDesktop(dropdownContent, 'logout')}
          </ul>
        </div>
      </div>
    );
  }

  displayCountBadge = (item) => {
    if (item.count > 0) {
      return <Badge theme="nav">{item.count}</Badge>;
    }
    return null;
  };
  /**
   * On redirect of the user to the proper home location by account
   * @function
   */
  redirectToHome = () => {
    let doctor_id = this.getDoctorId() ? this.getDoctorId() : this.state.user_id;
    const role = this.state.role;

    if (role.toLowerCase() === 'business') {
      return '/business/portal/cases';
    } else {
      return { pathname: `/portal/${doctor_id}/dashboard`, state: { reset_list: 'true' } };
    }
  };

  /**
   * Callback function to set Shopify Id on first customer creation
   * @param int shopify_id
   */
  setNewShopifyId = (shopify_id) => {
    this.setState({
      shopify_id: shopify_id,
    });
  };

  /**
   * Link Customer to the storefront
   */
  storefrontLink = () => {
    openStorefrontLink(this.getStorefrontDoctorInfo(), this.setNewShopifyId);
  };

  /**
   * SSO Login API Call
   */
  multipassLogin = () => {
    multipassLogin(this.getStorefrontDoctorInfo());
  };

  /**
   * Gets doctor info for opening storefront link
   *
   * @function
   * @returns {object} - Doctor information
   */
  getStorefrontDoctorInfo = () => {
    return {
      doctor_id: this.state.doctor_id,
      shopify_id: this.state.shopify_id,
      email: this.state.email,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      account_link_id: this.state.account_link_id,
      role: this.state.doctor_role,
    };
  };

  displayNavBar(menuContent, dropdownContent) {
    const handleHambugerClick = () => {
      this.toggleSideBar();
      this.props.closeHelpSidebar();
    };

    return (
      <nav className={`navbar ${this.props.sticky ? 'sticky' : ''}`}>
        <div className="navbar__menu__toggle" onClick={handleHambugerClick}>
          <HamburgerMenu active={this.state.sideBarOpen} />
        </div>
        <div className="navbar__content" onClick={() => this.props.closeHelpSidebar()}>
          <Link className="navbar__logo" to={this.redirectToHome()} onClick={this.closeSideBar}>
            <SDS />
          </Link>
          <Link className="navbar__logo navbar__logo--small" to={this.redirectToHome()} onClick={this.closeSideBar}>
            <InLogo style={{ minHeight: '34px', minWidth: '34px' }} />
          </Link>
          <div className="navbar__menu">
            {menuContent.map((item) =>
              item.label === 'Storefront' && item.display ? (
                /* eslint-disable-next-line */
                <a key="storefront" className="navbar__menu__item" onClick={this.storefrontLink}>
                  {item.showLogoInNavBar && item.logo}
                  InBrace Shop
                </a>
              ) : item.display ? (
                <NavLink
                  key={item.label}
                  className="navbar__menu__item"
                  exact
                  activeClassName="navbar__menu__item--active"
                  isActive={item.active}
                  to={{
                    pathname: item.link,
                    search: item.browser_search ? item.browser_search : '',
                    state: item.browser_state ? item.browser_state : undefined,
                  }}
                  onClick={() => {
                    if (item.disabled) {
                      item.disabledCallback();
                    }
                    this.closeSideBar();
                  }}
                >
                  {item.showLogoInNavBar && item.logo}
                  <span className="navbar__menu__item__label">
                    {item.label}
                    {this.displayCountBadge(item)}
                  </span>
                </NavLink>
              ) : null
            )}
          </div>
        </div>
        {this.displayAccountDropdown(dropdownContent)}
      </nav>
    );
  }

  displaySideBar(menuContent, dropdownContent) {
    const sideBarClassName = this.state.sideBarOpen ? 'sidebar-container sidebar--open' : 'sidebar-container';

    const sideBarMenuClassNames = ['sidebar__menu'];
    if (this.props.appHasMessageInfo) {
      sideBarMenuClassNames.push('has-message-info');
    }
    if (this.props.creditHold) {
      sideBarMenuClassNames.push('has-credit-hold-message');
    }
    const sideBarMenuClassName = sideBarMenuClassNames.join(' ');

    const content = [...menuContent, ...dropdownContent];
    return (
      <nav
        className={sideBarClassName}
        style={{
          top: `${NAVBAR_HEIGHT}px`,
        }}
      >
        <div className="sidebar">
          <div className="sidebar__logo">
            <InLogo />
          </div>
          <div className={sideBarMenuClassName}>
            {content.map((item) => {
              if (item.display) {
                if (item.label === 'Storefront') {
                  return (
                    /* eslint-disable-next-line */
                    <a key="storefront" className="sidebar__menu__item" onClick={this.storefrontLink}>
                      {item.logo}
                      InBrace Shop
                    </a>
                  );
                }

                if (item.label === 'Help') {
                  return (
                    <div key="help" className="sidebar__menu__item" {...item.props}>
                      {item.logo}
                      Help
                    </div>
                  );
                }

                if (item.component) {
                  return (
                    <item.component key={item.label} className="sidebar__menu__item" {...item.props}>
                      {item.logo}
                    </item.component>
                  );
                }

                return (
                  <NavLink
                    key={item.label}
                    className="sidebar__menu__item"
                    exact
                    activeClassName="sidebar__menu__item--active"
                    isActive={item.active}
                    to={{
                      pathname: item.link,
                      search: item.browser_search ? item.browser_search : '',
                      state: item.browser_state ? item.browser_state : undefined,
                    }}
                    onClick={() => {
                      if (item.disabled) {
                        item.disabledCallback();
                      }
                      this.closeSideBar();
                    }}
                  >
                    {item.logo}
                    <span className="sidebar__menu__item__label">{item.label}</span>
                  </NavLink>
                );
              }
              return null;
            })}
          </div>
        </div>
      </nav>
    );
  }

  /**
   * Determines if doctor is enrolled in given program
   * @function
   * @param {string} program - Program name
   * @returns {boolean} - True or false
   */
  hasProgramEnrollment = (program) => {
    return this.state.program_enrollment.includes(program);
  };

  render() {
    if (this.state.not_found) {
      return (
        <div className="fullview">
          <NotFound />
        </div>
      );
    } else if (this.state.loading) {
      return null;
    } else {
      return (
        <UserPermissionsContext.Consumer>
          {(user_roles_and_permissions) => {
            const menuContent = this.getNavBarContent(user_roles_and_permissions.permissions);
            const dropdownContent = this.getDropdownContent(user_roles_and_permissions);
            return (
              <>
                {this.displayNavBar(menuContent, dropdownContent)}
                {this.displaySideBar(menuContent, dropdownContent)}
                <Gen1AlertModal
                  doctor_id={this.state.doctor_id}
                  show={this.state.gen_1_alert}
                  onCloseButtonClick={() => {
                    this.setState({ gen_1_alert: false });
                  }}
                />
              </>
            );
          }}
        </UserPermissionsContext.Consumer>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    profile_image: getProfileImage(state),
    profile_metadata: getProfileMetadata(state),
    loggedDoctorCaseCount: getLoggedDoctorCaseCount(state),
    showHelpSidebar: getShowHelpSidebar(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchLoggedDoctorCaseCount,
      fetchProfilePicture,
      closeHelpSidebar,
      openHelpSidebar,
      toggleHelpSidebar,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));
