// External Libs
import React, { Component } from 'react';

// Internal Libs
import Modal from '../../modal/modal';
import { removeCaseIdInitialNumber } from '../../../common/functions';
import { userHasPermission } from '../../../common/permission';
import { UserPermissionsContext } from '../../../context/user_permission';

/**
 * Displays label generation selection step
 * @component
 * @alias LabelSelection
 * @category BPP
 */
class LabelSelection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSuccessModal: false,
      showFailedModal: false,
    };
  }

  toggleSuccessModal = () => {
    this.setState({ showSuccessModal: !this.state.showSuccessModal });
  };

  toggleFailedModal = () => {
    this.setState({ showFailedModal: !this.state.showFailedModal });
  };

  SuccessModal = () => {
    const header_text = `Mark as Success - ${removeCaseIdInitialNumber(this.props.case_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you would like to force the label generation process to succeed?"
        confirm_btn_text="Mark as Success"
        onConfirmButtonClick={this.props.markGenerationAsSuccess}
        close_btn_text="Cancel"
        onCloseButtonClick={this.toggleSuccessModal}
      />
    );
  };

  FailedModal = () => {
    const header_text = `Mark as Failed - ${removeCaseIdInitialNumber(this.props.case_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you would like to force the label generation process to fail?"
        confirm_btn_text="Mark as Failed"
        onConfirmButtonClick={this.props.markGenerationAsFailed}
        close_btn_text="Cancel"
        onCloseButtonClick={this.toggleFailedModal}
      />
    );
  };

  onCancelGenerateModal = (event) => {
    this.toggleGenerateModal();
  };

  render() {
    return (
      <UserPermissionsContext>
        {(user_roles_and_permissions) => {
          const has_generate_permission = userHasPermission('CASE_LABEL_GENERATION', user_roles_and_permissions.permissions);
          const has_bypass_permission = userHasPermission('CASE_LABEL_MARK_BYPASS', user_roles_and_permissions.permissions);
          return has_generate_permission || has_bypass_permission ? (
            <>
              <div className="process-box-intro">
                <div className="process-box-title">Label Generation</div>
                <div>The selected labels are currently being generated...</div>
              </div>

              <div className="row text-center">
                <i className="fa fa-refresh font-size-xlg rotate" aria-hidden="true" />
              </div>

              {has_bypass_permission ? (
                <div className="button-panel">
                  <button type="button" className="btn btn-light" onClick={this.toggleSuccessModal}>
                    Mark as Success
                  </button>

                  <button type="button" className="btn btn-light" onClick={this.toggleFailedModal}>
                    Mark as Failed
                  </button>
                </div>
              ) : null}
              {this.state.showSuccessModal ? this.SuccessModal() : null}
              {this.state.showFailedModal ? this.FailedModal() : null}
            </>
          ) : (
            <div className="process-box-intro">
              <div className={`center-text content-center${!this.props.gen_2 ? ' margin-top-30' : ''}`}>Label generation process in progress...</div>
            </div>
          );
        }}
      </UserPermissionsContext>
    );
  }
}

export default LabelSelection;
