// External Libs
import React, { Component } from 'react';

// Internal Libs
import { userHasPermission } from '../../../../common/permission';
import { UserPermissionsContext } from '../../../../context/user_permission';

class Override extends Component {
  render() {
    const { adjusted_bmf_file } = this.props.state;
    const isComplete = this.props.isComplete();

    return (
      <div>
        <UserPermissionsContext>
          {(user_roles_and_permissions) => {
            return userHasPermission('IREQ_WIRE_SELECTION_GENERATE', user_roles_and_permissions.permissions) && !isComplete ? (
              <div>
                {/* eslint-disable-next-line */}
                <a
                  className="float-right"
                  onClick={() => {
                    this.props.wsAction('goback');
                  }}
                >
                  <i className="fa fa-long-arrow-left" aria-hidden="true" /> Return to Wire Selection
                </a>
              </div>
            ) : null;
          }}
        </UserPermissionsContext>
        {adjusted_bmf_file && (
          <div className="margin-bottom-10">
            <span
              className="underline-text viewable-text file-span"
              onClick={() => {
                this.props.setModal('bmf_pdf');
              }}
            >
              Adjusted Bracket Measurements Form <i className="fa fa-file-pdf-o" aria-hidden="true" />
            </span>
          </div>
        )}
        <span>The wire selection process has been overridden...</span>
      </div>
    );
  }
}

export default Override;
